@mixin responsive($breakpoint){
    @if $breakpoint == Exsmall{
        @media only screen and (max-width:474px){
            @content
        };
    }
    @if $breakpoint == phone{
        @media only screen and (max-width:768px){
            @content
        };
    }
    @if $breakpoint == tab-port{
        @media only screen and (max-width:992px){
            @content
        };
    }
    @if $breakpoint == device1200{
        @media only screen and (min-width:1200px){
            @content
        };
    }
    @if $breakpoint == tab-land{
        @media only screen and (max-width:1400px){
            @content
        };
    }
    @if $breakpoint == big-desktop{
        @media only screen and (min-width:1800px){
            @content
        };
    }
}

@mixin  border-radius($radius) {
    -webkit-border-radius:$radius;
    -moz-border-radius:$radius;
    -ms-border-radius:$radius;
    border-radius:$radius;
}
@mixin btn($fontcolor: #FAFAFA){
    outline: 0;
    border: 0 !important;
    width: 100%;
    padding: 15px;
    background-color: #FF5722 !important;
    color: $fontcolor;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;
    font: normal normal 600 19px/26px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
    opacity: 1;
  }
  
  @mixin message(){
    color: #90A4AE;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    margin-top: 30px;
  }
  
  @mixin input(){
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    outline: 0;
    font-size: 15px;
    box-sizing: border-box;
    background-color: #FCFCFC !important;
    box-shadow: 0px 0px 2px #0000000a;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
  }
  @mixin acBtn(){
    border: 1px solid #F6491E;
    border-radius: 4px;
    opacity: 1;
    background-color: #fff;
    font: normal normal 600 16px Segoe UI;
    color: #F6491E;
    text-transform: uppercase;
    padding: 8px 20px;
    display: flex;
    align-items: center;
    & span{
        font-size: 25px;
        display: flex;
        margin-right: 10px;
    }
  }