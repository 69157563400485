@import "./scss/mediaQueries.scss";

$small: 474px;
$medium: 768px;
$large : 1600px;
$tab-landscape : 1400px;

html {
  overflow-x: hidden;
}

h1,
h2,
h3,
h4,
span,
p {
  font-family: Source Sans Pro;
}

.pt60 {
  padding-top: 60px;
}

.pb60 {
  padding-bottom: 60px;
}

#basic-navbar-nav {
  justify-content: flex-end;
}

p {
  @include responsive(phone) {
    font: normal normal 300 16px/30px Source Sans Pro !important;
    margin-bottom: 15px;
  }
}

.navBar {
  position: absolute !important;
  width: 100%;
  z-index: 9;
  padding-top: 20px !important;
}

.bannerSec .bgImg img {
  object-fit: cover;
  width: 100%;
  height: 100%;

}

.bannerSec {
  background: #140400 0% 0% no-repeat padding-box;
  height: calc(100vh - 50px);
  min-height: auto;
  position: relative;
  padding-top: 60px;
}

a.revupLogo.navbar-brand img {
  width: 165px;
}

.navbar-expand-lg .navbar-nav .nav-link {
  color: #fff !important;
  opacity: .8;
  font-size: 16px;
}

.menuNav {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 5;
  background: transparent;
  padding: 0;
}

.menuNav.stickyMenu {
  background: #ffffff;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.1s ease-out;
  box-shadow: 0 4px 24px 0 rgb(103 151 255 / 10%), 0 12px 64px 0 rgb(103 151 255 / 10%);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  height: 75px;
}

span.lnamespn {
  display: block;
  font-size: 18px !important;
}

.locPrt {
  margin-top: 10px;
}

.navBar {
  transition: all 1s;
  position: relative;
}

.menuNav.stickyMenu .navBar {
  padding-top: 0.5rem !important;
  transition: all 1s;
}

.menuNav.stickyMenu .navBar .navbar-nav .nav-link {
  color: #191919 !important;
  opacity: 1;
  font-size: 16px;
  @include responsive(phone){
    color: #fff !important;
  }
  svg path{
    @include responsive(phone){
      fill: #fff !important;
    }    
  }
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>") !important;
}

.navbar-expand-lg .navbar-nav {
  gap: 15px;
}

.bgImg {
  height: 100%;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
}

.WrapperBnr {
  height: 100%;
  display: flex;
}

.bnrLeft h1 {
  font: normal normal 200 54px/61px Source Sans Pro;
  letter-spacing: 0px;
  color: #F6491E;
  opacity: 1;
  margin-bottom: 20px;

  span {
    display: block;
  }
}

.bnrLeft p {
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 0.69;
  font: normal normal 300 20px/28px Source Sans Pro;
}

.bnrLBtns {
  display: flex;
  gap: 20px;
  margin-top: 50px;
}

.bnrLBtns .btnbnr {
  height: 60px;
}

.bnrLBtns .btnbnr img {
  height: 100%;
  width: 100%;
}

.bannerSec {
  background-size: cover;
}

.bnrRight .bnrRin img {

  max-width: 100%;
  width: 100%;
  margin-top: 180px;
  z-index: 9;
}

.h100 {
  height: 100%;
}

.colBnrLeft {
  align-self: center;
}

.bnrRin {
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.bnrRin::before {
  position: absolute;
  content: "";
  width: calc(100% - 180px);
  height: 90%;
  background: url(./images/graph.png) no-repeat 0 50px;
  border-radius: 26px 26px 0px 0px;
  opacity: .4;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background-size: contain;
  z-index: 1;
}

.bnrRin::after {
  position: absolute;
  content: "";
  width: calc(100% - 180px);
  height: 90%;
  background: transparent linear-gradient(137deg, #F77902 0%, #F64B02 100%) 0% 0% no-repeat padding-box;
  border-radius: 26px 26px 0px 0px;
  opacity: 1;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  background-size: contain;
}

.bnrRight {
  padding: 50px;
  padding-bottom: 0;
}


.navbar-expand-lg .navbar-nav .nav-link.menuReg {
  text-transform: uppercase;
  color: #F6491E !important;
}

.navbar-expand-lg .navbar-nav .nav-link.menuReg.loginRup {
  text-transform: uppercase;
  color: #F6491E !important;
  padding: 5px 15px !important;
  display: flex;
  align-items: center;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border-radius: 4px;
}

// slider content
.prodSlider {
  background: #F6F6F6 0% 0% no-repeat padding-box;
}

.carSlider {
  position: relative;
  margin-top: -40px;
  padding: 52px;
  padding-top: 40px;
  padding-bottom: 40px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  border-radius: 12px;
  opacity: 1;
  z-index: 9;

  @include responsive(phone) {
    margin-top: 0;
  }

  & .carSlHd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;

    & h2 {
      font-family: 'Source Sans Pro', sans-serif;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;
      font-size: 30px;
      line-height: 40px;

      @include responsive(phone) {
        line-height: 30px;
        font-size: 25px;
        margin: 0;
      }
    }
  }

  & .carSlItem {
    padding-right: 35px;

    & .carSlIn {
      & img {
        width: 80%;
        max-width: 100%;
        margin: auto;
        height: 120px;
        object-fit: contain;

        @include responsive(phone) {
          height: 100px;
        }
      }
    }

    & .carSlDec {
      margin-top: 30px;

      & h2 {
        text-align: left;
        font: normal normal 300 25px/30px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 1;
        height: 70px;
        display: flex;
        align-items: center;

        @include responsive(phone) {
          font: normal normal 300 20px/28px Source Sans Pro;
        }
      }

      & .review {
        & span {
          text-align: left;
          font: normal normal normal 18px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 1;

          @include responsive(phone) {
            font-size: 14px;
          }

          & i {
            color: #ffd816;
            margin-right: 2px;
          }
        }

        & span.r-count {
          color: #F6491E;
          display: inline-block;
          padding-left: 10px;
        }
      }

      button.pickBtn {
        background: #F6491E 0% 0% no-repeat padding-box;
        border-radius: 4px;
        opacity: 1;
        border: none;
        color: #fff;
        padding: 10px 30px;
        text-transform: uppercase;
        margin-top: 20px;
        width: 100%;

        @include responsive(phone) {
          font-size: 14px;
        }
      }

      @include responsive(phone) {
        margin-top: 10px;
      }
    }
  }

  & .carslhdR {
    display: flex;
    gap: 8px;

    & .leftSl,
    & .rightSl {
      width: 25px;
      height: 25px;
      display: inline-flex;
      justify-content: center;
      border-radius: 50%;
      border: 2px solid red;
      align-items: center;
      color: #F6491E;
      cursor: pointer;
    }
  }
}

@media screen and (min-width: $large) {
  .bnrRin::before {
    position: absolute;
    content: "";
    width: calc(100% - 180px);
    height: 90%;
    background: url(./images/graph.png) no-repeat 0 130px;
    border-radius: 26px 26px 0px 0px;
    opacity: .4;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-size: contain;
    z-index: 1;
  }

  .bnrRin::after {
    position: absolute;
    content: "";
    width: calc(100% - 180px);
    height: 90%;
    background: transparent linear-gradient(137deg, #F77902 0%, #F64B02 100%) 0% 0% no-repeat padding-box;
    border-radius: 26px 26px 0px 0px;
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-size: contain;
  }
}

@media(max-width:1400px) {
  .bnrRin::before {
    background: url(./images/graph.png) no-repeat 0 130px;
  }
}

@media(max-width:$tab-landscape) {
  .bnrRin::before {
    position: absolute;
    content: "";
    width: calc(100% - 180px);
    height: 90%;
    background: url(./images/graph.png) no-repeat 0 50px;
    border-radius: 26px 26px 0px 0px;
    opacity: .4;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-size: contain;
    z-index: 1;
  }

  .bnrRin::after {
    position: absolute;
    content: "";
    width: calc(100% - 180px);
    height: 90%;
    background: transparent linear-gradient(137deg, #F77902 0%, #F64B02 100%) 0% 0% no-repeat padding-box;
    border-radius: 26px 26px 0px 0px;
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-size: contain;
  }
}

@media(max-width:1200px) {
  .bnrRin::before {
    position: absolute;
    content: "";
    width: calc(100% - 180px);
    height: 90%;
    background: url(./images/graph.png) no-repeat 0 50px;
    border-radius: 26px 26px 0px 0px;
    opacity: .6;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-size: contain;
    z-index: 1;
  }

  .bnrRin::after {
    position: absolute;
    content: "";
    width: calc(100% - 180px);
    height: 90%;
    background: transparent linear-gradient(137deg, #F77902 0%, #F64B02 100%) 0% 0% no-repeat padding-box;
    border-radius: 26px 26px 0px 0px;
    opacity: 1;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background-size: contain;
  }
}

@media(max-width:992px) {
  .bannerSec {
    height: auto;
    padding-top: 110px;
  }

  .bnrRight {
    padding: 50px;
    padding-bottom: 0;
    padding-top: 30px;
  }

  .prodSlider {
    padding-top: 50px;
  }

  .carSlider {
    margin-top: 0;
  }

  .bnrRight .bnrRin img {
    margin-top: 170px;
    margin-bottom: 40px;
  }

  .bnrLBtns {
    justify-content: center;
    margin-top: 30px;
  }

  .carSlider {
    margin-top: 0;
  }

  .navbar-toggler {
    border: none !important;
    padding: 0 !important;
  }

  #basic-navbar-nav {
    justify-content: flex-end;
    background-color: #120401cc;
    padding: 15px;
    backdrop-filter: blur(5px);
  }

  .bnrRin::before {
    height: 100%;
    width: calc(100% - 80px);
  }

  .bnrRin::after {
    width: calc(100% - 80px);
  }
}

@media(max-width:768px) {
  .WrapperBnr {
    margin-top: 65px;
  }

  .bnrLeft h1 {
    font-size: 40px;
  }

  .bnrLeft {
    text-align: center;
  }
}

@media(max-width:474px) {
  .bnrRight {
    padding: 0;
  }

  .bnrRight .bnrRin img {
    margin-top: 160px;
  }
}

@media(max-width:360px) {
  .bnrRight {
    padding: 0;
  }

  .bnrRight .bnrRin img {
    margin-top: 120px;
  }
}

// contact Cta
.contactCta {
  background: #F6F6F6 0% 0% no-repeat padding-box;

  &.noBg {
    background-color: #fff;
  }

  & .ctaRow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 20px;
    background-size: cover;
    border-radius: 12px;
    margin: 30px 0 40px;
    overflow: hidden;
    position: relative;
    background-position: left center;

    & h2 {
      margin: 0;
      font: normal normal 300 27px/61px Source Sans Pro;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      position: relative;

      @include responsive(phone) {
        font: normal normal 300 22px/28px Source Sans Pro;
      }
    }

    & button {
      border: none;
      padding: 10px 15px;
      margin-left: 20px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      border-radius: 4px;
      font: normal normal 600 18px/24px Segoe UI;
      letter-spacing: 0px;
      color: #110403;
      text-transform: uppercase;
      opacity: 1;
      position: relative;
      padding: 10px 30px;

      @include responsive(phone) {
        font: normal normal 600 14px/24px Segoe UI;
        padding: 10px 20px;
        width: 200px;
      }
    }

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      background-color: #000;
      opacity: 0.58;
    }
  }
}


// services section

.servicesSec {
  padding-top: 60px;

  & .servHd {
    text-align: center;

    & span {
      font: normal normal 200 22px/15px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      text-transform: uppercase;
      opacity: 1;

      @include responsive(phone) {
        font: normal normal 200 16px/15px Source Sans Pro;
        padding: 10px 20px;
        width: 200px;
      }
    }

    & h2 {
      font: normal normal 200 39px/61px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;

      @include responsive(phone) {
        font: normal normal 200 32px/36px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 1;
        margin-top: 10px;
      }
    }
  }

  .servRow {
    margin: 0;
    margin-top: 50px;
    margin-bottom: 60px;

    & .serColL {
      align-self: center;

      & .serImg {
        & img {
          width: 100%;
          max-width: 100%;
        }
      }
    }

    & .serColR {
      & .servList {
        & .servUl {
          @include responsive(tab-port) {
            padding-left: 0;
            padding-top: 40px;
          }

          & .serli {
            list-style: none;
            display: flex;
            align-items: center;
            margin-bottom: 25px;

            & .liLft {
              margin-right: 20px;

              & img {
                width: 60px;
                height: 60px;
                object-fit: none;
                border-radius: 50%;
                overflow: hidden;
                box-shadow: 1px 1px 11px #cbcbcb;
              }

            }

            & .liRight {
              & h3 {
                text-align: left;
                font: normal normal normal 22px/32px Source Sans Pro;
                letter-spacing: 0px;
                color: #110403;
                opacity: 1;

                @include responsive(phone) {
                  font: normal normal normal 18px/20px Source Sans Pro;
                }
              }

              & p {
                text-align: left;
                font: normal normal 300 20px/28px Source Sans Pro;
                letter-spacing: 0px;
                color: #110403;
                opacity: 0.69;
                margin: 0;

                @include responsive(phone) {
                  font: normal normal 300 16px/22px Source Sans Pro;
                }
              }
            }
          }
        }
      }
    }
  }
}

// shopCta

.shopCta {
  position: relative;

  & .shopCtaRow {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 40px;
    background-size: cover;
    border-radius: 12px;
    margin: 30px 0 40px;
    position: relative;
    background-position: left center;
    height: 250px;

    @include responsive(tab-port) {
      height: auto;
      margin-bottom: 0;
    }

    &:before {
      position: absolute;
      width: 100%;
      height: 100%;
      content: "";
      background: transparent linear-gradient(180deg, #F77902 0%, #F64B02 100%) 0% 0% no-repeat padding-box;
      opacity: .95;
      border-radius: 12px;
    }
  }

  & .colSctaLeft {
    display: flex;
    align-items: center;

    & .shpGadL {
      position: relative;

      @include responsive(phone) {
        text-align: center;
      }

      & span {
        font: normal normal 200 22px/0 Source Sans Pro;
        letter-spacing: 0px;
        color: #FFFFFF;
        text-transform: uppercase;
        opacity: 1;
        margin-bottom: 20px;
        display: inline-block;

        @include responsive(phone) {
          font: normal normal 200 18px/0 Source Sans Pro;
        }
      }

      & h2 {
        font: normal normal 200 39px/40px Source Sans Pro;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;

        @include responsive(phone) {
          font: normal normal 200 32px/40px Source Sans Pro;
        }
      }

      & button {
        border: none;
        padding: 10px 60px;
        background: #FFFFFF 0% 0% no-repeat padding-box;
        border-radius: 4px;
        font: normal normal 600 18px/24px Segoe UI;
        letter-spacing: 0px;
        color: #110403;
        text-transform: uppercase;
        opacity: 1;
        position: relative;
        margin-top: 15px;
      }

    }
  }

  & .shpImgRight {
    display: flex;
    justify-content: center;

    & img {
      position: absolute;
      height: calc(100% + 50px);
      top: -30px;

      @include responsive(tab-port) {
        height: auto;
        top: 0;
        width: 70%;
        position: relative;
        margin-top: 40px;
      }
    }
  }
}

// how it works


.secHd {
  text-align: center;

  & span {
    font: normal normal 200 22px/15px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    text-transform: uppercase;
    opacity: 1;

    @include responsive(phone) {
      font: normal normal 200 16px/15px Source Sans Pro;
    }
  }

  & h2 {
    font: normal normal 200 39px/61px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;

    @include responsive(phone) {
      font: normal normal 200 32px/36px Source Sans Pro;
    }
  }
}

.howItWork {
  & .hiwRow {
    margin-top: 40px;
  }

  & .hiwItem {
    text-align: center;

    @include responsive(phone) {
      margin-bottom: 30px;
      padding: 50px;
      padding-top: 0;
      padding-bottom: 0;
    }

    & .hiwThumb {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      box-shadow: 1px 1px 11px #cbcbcb;
      position: relative;
      margin: auto;
      margin-bottom: 20px;
      display: flex;
      align-items: center;
      justify-content: center;

      & img {
        width: 100%;
        height: 100%;
        object-fit: none;
        border-radius: 50%;
        overflow: hidden;
        transform: scale(.8);
      }
    }

    & .hiwDec {
      & h3 {
        font: normal normal normal 22px/32px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 1;

        @include responsive(phone) {
          font: normal normal normal 18px/20px Source Sans Pro;
        }
      }

      & p {
        font: normal normal 300 20px/28px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 0.69;

        @include responsive(phone) {
          font: normal normal 300 16px/22px Source Sans Pro;
        }
      }
    }

  }
}


// FAQs

.faqsSec {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  opacity: 1;

  & .accordion-button {
    font: normal normal normal 22px/32px Source Sans Pro;

    @include responsive(phone) {
      font: normal normal 300 18px/20px Source Sans Pro !important;
    }
  }

  & .accordion-button:not(.collapsed) {
    font: normal normal normal 22px/32px Source Sans Pro;
    letter-spacing: 0px;
    color: #F6491E;
    background: none;
    padding-left: 0;
    padding-right: 0;
    box-shadow: none;
  }

  & .accordion-body {
    padding: 1rem 1.25rem;
    font: normal normal 300 20px/34px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 0.91;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;

    @include responsive(phone) {
      font: normal normal 300 16px/22px Source Sans Pro;
    }
  }

  & .accordion-item {
    background-color: transparent;
    border: none;
  }

  & .accordion-button {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    padding: 10px 0;
  }

  & .accordion-button::after {
    display: none;
  }

  .faqAcc {
    padding-top: 25px;
  }

  & .accordion-button:focus {
    box-shadow: none;
  }
}

.DownloadCta {
  & .DownloadCtaRow {
    background-size: 65%;
    background-position: 0px 70px;
    background-repeat: no-repeat;
    height: 360px;
    margin: 80px 0;

    @include responsive(tab-port) {
      background-size: 100%;
    }

    & .bnrLBtns {
      display: flex;
      gap: 20px;
      margin-top: 30px;
    }

    &:before {
      opacity: .80;
    }
  }

  .shpImgRight {
    position: relative;

    & img {
      position: absolute;
      height: calc(100% + 150px);
      top: -75px;
      right: 0;
    }

    &.sctaRt {
      @include responsive(tab-port) {
        display: none;
      }
    }
  }

  & .ddLeft {
    & p {
      color: #fff;
      font: normal normal 300 20px/28px Source Sans Pro;
    }

    & h2 {
      margin-bottom: 15px;
    }
  }
}

.pt80 {
  padding-top: 80px;
}

// footer
.footer {
  background: #110403 0% 0% no-repeat padding-box;
  opacity: 1;
  margin-top: 60px;

  & .footerRow {
    margin: 0;
    position: relative;
    padding-bottom: 20px;

    &::before {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 1px;
      background-color: #707070;
      content: "";
      opacity: 0.51;
    }
  }

  & .fooIn {
    @include responsive(tab-port) {
      margin-bottom: 40px;
    }

    &.fif {
      padding-right: 200px;

      @include responsive(phone) {
        padding-right: 0;
      }
    }

    &.fis {
      padding-right: 100px;

      @include responsive(phone) {
        padding-right: 0;
      }
    }

    & h3 {
      font: normal normal 200 27px/35px Source Sans Pro;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      margin-bottom: 10px;
    }

    & p {
      font: normal normal 300 18px/28px Source Sans Pro;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 0.91;

      @include responsive(phone) {
        font: normal normal 300 16px/28px Source Sans Pro;
      }
    }

    & .ssicons {
      display: flex;
      gap: 15px;
      margin-top: 25px;

      & .ssItem {
        & i {
          color: #fff;
          opacity: 0.68;
        }
      }
    }

    & .fooUl {
      list-style-type: none;
      padding-left: 0;

      & li {
        display: flex;
        font: normal normal 300 18px/32px Source Sans Pro;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 0.91;
        gap: 10px;

        @include responsive(phone) {
          font: normal normal 300 16px/28px Source Sans Pro;
          margin-bottom: 10px;
        }

        & span p {
          font: normal normal 300 18px/32px Source Sans Pro;
          letter-spacing: 0px;
          color: #FFFFFF;
          opacity: 0.91;
          margin-bottom: 0;

          @include responsive(phone) {
            font: normal normal 300 16px/28px Source Sans Pro;
          }
        }

        & i {
          font-size: 16px;
          margin-top: 10px;
        }
      }
    }
  }

  & .fooCpyRight {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 0 30px;

    @include responsive(phone) {
      display: block;
      text-align: center;
    }

    & .cpL p {
      font: normal normal 300 16px/32px Source Sans Pro;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 0.66;
      margin-bottom: 0;
    }

    & .cpR span {
      color: #FFFFFF;
      opacity: 0.66;
      font: normal normal 300 16px/32px Source Sans Pro;
      padding: 0 5px;

      & a {
        font: normal normal 300 16px/32px Source Sans Pro;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 0.66;
        text-decoration: none;
      }
    }
  }
}


// Car Details Page

// Single product Banner


.spBanner.bannerSec {
  height: auto;
  padding: 145px 0;
  background-position: 50% 50%;
  @include responsive(Exsmall) {
    padding: 100px 0;
  }

  & .spRow {
    text-align: center;

    & h1 {
      font: normal normal 200 54px/61px Source Sans Pro;
      letter-spacing: 0px;
      color: #F6491E;
      opacity: 1;
      font-weight: 400;
      margin-bottom: 10px;
      @include responsive(phone) {
        font: normal normal 300 40px Source Sans Pro;
        margin-bottom: 15px;
      }

    }

    & p {
      text-align: center;
      font: normal normal 300 22px/32px Source Sans Pro;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 0.69;

      @include responsive(device1200) {
        width: 45%;
        margin: auto;
      }
    }
  }
}
.spBanner.bannerSec.low-hieght{
   padding: 85px 0;
}

// BookNow
.bookNowSection {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  padding-bottom: 60px;

  & .wrapBookNow {
    background-color: #fff;
    margin-top: -60px;
    position: relative;
    box-shadow: 0px 0px 48px #00000008;
    @include border-radius(12px);
    overflow: hidden;

    & .bnRow {
      >.colPdLeft {
        padding: 0;
      }

      >.colPdRight {
        padding: 0;
      }

      align-items: center;
      border-radius: 12px;
      padding: 40px;
      margin: 0;
      @include border-radius(12px);

      & .pdLeft {
        padding: 0 30px;
        margin-bottom: 15px;

        padding-left: 0;
        @include responsive(phone){

          padding-left: 0;
          padding-right: 0;
          padding-bottom: 30px;
          margin-bottom: 0 !important;
        }

        & img {
          max-width: 100%;
        }
      }

      & .pdRight {
        h2 {
          font: normal normal 300 31px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 1;

          @include responsive(phone) {
            font: normal normal 300 28px Source Sans Pro;
          }
        }

        & .review {
          & span {
            text-align: left;
            font: normal normal normal 18px Source Sans Pro;
            letter-spacing: 0px;
            color: #110403;
            opacity: 1;

            & i {
              color: #ffd816;
              margin-right: 2px;
            }

            &.r-count {
              color: #F6491E;
              display: inline-block;
              padding-left: 10px;
            }
          }
        }

        & .priceForCar {
          padding-top: 30px;

          @include responsive(phone) {
            padding-top: 20px;
          }

          & h2 {
            font: normal normal 600 37px Segoe UI;
            letter-spacing: 0px;
            color: #110403;
            text-transform: uppercase;
            opacity: 1;
            margin-bottom: 0;

            @include responsive(phone) {
              font: normal normal 600 28px Segoe UI;
            }

            & span {
              font: normal normal 300 20px Segoe UI;
              letter-spacing: 0px;
              color: #110403;
              opacity: 0.59;
              text-transform: lowercase;
            }
          }

          & .carAlert {
            letter-spacing: 0px;
            color: #F6491E;
            opacity: 1;

            i {
              margin-right: 10px;
            }
          }
        }
      }
    }

    & .enquiryArea {
      background: #F6491E 0% 0% no-repeat padding-box;
      display: flex;
      align-items: center;
      justify-content: space-between;
      align-content: center;
      padding: 20px 40px;

      @include responsive(phone) {
        display: block;
        padding: 30px 40px;
      }

      & .enqLeft {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 1;
        max-width: 70%;
        width: 100%;

        gap: 30px;
        @include responsive(phone){

          display: block;

        }

        & .elItem {
          cursor: pointer;

          @include responsive(phone) {
            margin-bottom: 25px;
          }

          >span {
            & i {
              margin-right: 5px;
            }
          }

          & span {
            font: normal normal 300 20px Source Sans Pro;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 0.68;

            @include responsive(Exsmall) {
              font: normal normal 600 16px Source Sans Pro;
            }
          }

          & h3 {
            margin-bottom: 0;
            font: normal normal 600 28px Source Sans Pro;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            display: flex;
            align-items: center;
            margin-top: 4px;

            @include responsive(Exsmall) {
              font: normal normal 600 22px Source Sans Pro;
            }

            & i {
              opacity: 0.61;
              margin-left: 10px;
            }
          }
        }
      }

      & .enqRight {
        .btnBn {
          border: none;
          padding: 20px 40px;
          font: normal normal 600 18px/26px Segoe UI;
          letter-spacing: 0px;
          color: #F6491E;
          text-transform: uppercase;
          opacity: 1;
          border-radius: 4px;
          background-color: #fff;

          @include responsive(phone) {
            padding: 10px 30px;
            margin-top: 20px;
          }
        }
      }
    }
  }
}

// Specification

.specifications {
  padding: 60px 0;

  & h2 {
    font: normal normal 200 39px/61px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
  }

  & .specRow {
    padding-top: 20px;

    & .colSpec {
      & .specItem {
        margin-bottom: 25px;

        @include responsive(Exsmall) {
          margin-bottom: 10px;
        }

        & .sitemHd {
          >span {
            font: normal normal 300 20px/28px Source Sans Pro;
            letter-spacing: 0px;
            color: #110403;
            opacity: 1;
          }
        }

        & .sitemCon {
          line-height: 48px;
          display: flex;
          align-items: center;

          & .sitemThumb {
            display: flex;

            & img {
              width: 24px;
              height: 34px;
              object-fit: contain;
            }
          }

          & .sitemRight {
            span {
              font: normal normal normal 27px/61px Source Sans Pro;
              letter-spacing: 0px;
              color: #110403;
              opacity: 1;
              padding-left: 10px;
            }
          }
        }
      }
    }
  }
}

// Description

.description {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  padding: 60px 0;

  & .decHd {
    & h2 {
      font: normal normal 200 39px/61px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;
    }
  }

  & .decCon {
    & p {
      font: normal normal 300 20px/34px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 0.91;
    }
  }
}

// customer review

.cusReview {
  padding: 60px 0;

  @include responsive(phone) {
    padding: 40px 0;
  }

  & h2 {
    font: normal normal 200 39px/61px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
  }

  & .crRow {
    & .colReview {
      padding-right: 100px;
      padding-top: 50px;

      @include responsive(phone) {
        padding-top: 20px;
        padding-right: 0;
      }

      & .reviewHd {
        display: flex;
        align-items: center;
        gap: 15px;

        & .rhdThumb {
          width: 80px;
          height: 80px;
          border-radius: 50%;
          overflow: hidden;

          & img {
            object-fit: cover;
            height: 100%;
            width: 100%;
          }
        }

        & .rhdReview {
          & h2 {
            font: normal normal normal 22px/32px Source Sans Pro;
            margin-bottom: 0;

            span {
              font: normal normal 300 20px/28px Source Sans Pro;
              letter-spacing: 0px;
              color: #110403;
              opacity: 0.49;
            }
          }

          & .reviewStar {
            display: flex;
            align-items: center;
            gap: 5px;
            margin-top: 2px;

            & i {
              color: #FFD816;
            }
          }
        }
      }

      & .cusComment {
        margin-top: 10px;

        & p {
          font: normal normal 300 20px/34px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 0.91;
          // width: 400px;
          word-break: break-all;
        }
      }
    }
  }
}

// modal Duration

.modalDuration {
  & .modal-dialog {
    max-width: 600px;
  }

  .modal-content {
    & .modal-header {
      & .modal-title {
        font: normal normal 200 31px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;

        @include responsive(phone) {
          font-size: 22px;
        }
      }
    }

    & .modal-footer {
      & .closeBtn {
        background-color: #ffffff;
        border-color: #6c757d;
        font: normal normal 600 14px/26px Segoe UI;
        letter-spacing: 0px;
        color: #110403;
        text-transform: uppercase;
        opacity: 1;
        min-width: 150px;

        @include responsive(phone) {
          min-width: 100%;
        }
      }

      & .saveBtn {
        background: #F6491E 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border-color: #F6491E;
        font: normal normal 600 14px/26px Segoe UI;
        letter-spacing: 0px;
        color: #110403;
        text-transform: uppercase;
        opacity: 1;
        min-width: 150px;
        color: #fff;

        @include responsive(phone) {
          min-width: 100%;
        }
      }
    }
  }
}

.chooseDuration {
  & .radio {
    &-input {
      visibility: hidden;
    }

    &-label {
      position: relative;
      padding-left: 35px;

      &:after {
        content: "";
        display: block;
        width: 12px;
        height: 12px;
        position: absolute;
        left: 4px;
        top: 4px;
        border-radius: 50%;
      }
    }

    &-border {
      width: 20px;
      height: 20px;
      display: inline-block;
      outline: solid 3px #d449e3;
      border-radius: 50%;
      position: absolute;
      left: 0px;
      top: 0px;
    }

    &-input:checked+&-label:after {
      transition: all 0.5s;
      background-color: #64236b;
    }
  }

  & form {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    justify-content: center;

    & .form-check {
      // flex: 1 0 20%;
      width: 150px;
      padding: 10px 15px;
      box-shadow: 0px 0px 48px #00000008;
      border: 0.5px solid #F6491E;
      border-radius: 20px;
      position: relative;
      margin-right: 0;
      overflow: hidden;
      cursor: pointer;

      @include responsive(Exsmall) {
        border-radius: 6px;
        padding: 6px 8px;
      }

      & input {
        border: 1px solid #f7633e;
        position: relative;
        text-align: center;
        padding: 8px;
        margin-left: 0;
        margin-left: 0;
        margin-right: 0;
        margin-top: 0;

        @include responsive(phone) {
          opacity: 0;
        }

        &:checked {
          background-color: #F6491E;
          border-color: #F6491E;

          @include responsive(phone) {
            &+label {
              background-color: #F6491E;
              border-color: #F6491E;
              color: #fff;
            }
          }
        }
      }

      & label {
        cursor: pointer;
        width: 100%;
        position: absolute;
        height: 100%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        display: flex;
        justify-content: center;
        align-items: center;

        @include responsive(phone) {
          font-size: 14px;
        }
      }
    }
  }
}

.modal-header {
  border-bottom: none;
}

.modal-footer {
  padding-top: 20px;
  justify-content: center !important;
}

.modalTimeDate {
  & .modal-dialog {
    max-width: 600px;
  }

  .modal-content {
    & .modal-header {
      & .modal-title {
        font: normal normal 200 31px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;

        @include responsive(phone) {
          font-size: 22px;
        }
      }
    }

    & .modal-footer {
      & .closeBtn {
        background-color: #ffffff;
        border-color: #6c757d;
        font: normal normal 600 14px/26px Segoe UI;
        letter-spacing: 0px;
        color: #110403;
        text-transform: uppercase;
        opacity: 1;
        min-width: 150px;

        @include responsive(phone) {
          min-width: 100%;
        }
      }

      & .saveBtn {
        background: #F6491E 0% 0% no-repeat padding-box;
        border-radius: 4px;
        border-color: #F6491E;
        font: normal normal 600 14px/26px Segoe UI;
        letter-spacing: 0px;
        color: #110403;
        text-transform: uppercase;
        opacity: 1;
        min-width: 150px;
        color: #fff;

        @include responsive(phone) {
          min-width: 100%;
        }
      }
    }
  }

  & .chooseTimeSlot {
    position: relative;
    padding: 20px 30px 10px;

    @include responsive(phone) {
      padding: 20px 15px 10px;
    }

    &::before {
      position: absolute;
      width: 100%;
      height: 1px;
      content: "";
      top: 0;
      left: 0;
      background-color: #000;
      opacity: .2;
      padding: 0;
    }

    & h3 {
      font: normal normal normal 22px/35px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;
      margin: 0;

      @include responsive(phone) {
        font: normal normal normal 18px/35px Source Sans Pro;
      }
    }

    & .radio {
      &-input {
        visibility: hidden;
      }

      &-label {
        position: relative;
        padding-left: 35px;

        &:after {
          content: "";
          display: block;
          width: 12px;
          height: 12px;
          position: absolute;
          left: 4px;
          top: 4px;
          border-radius: 50%;
        }
      }

      &-border {
        width: 20px;
        height: 20px;
        display: inline-block;
        outline: solid 3px #d449e3;
        border-radius: 50%;
        position: absolute;
        left: 0px;
        top: 0px;
      }

      &-input:checked+&-label:after {
        transition: all 0.5s;
        background-color: #64236b;
      }
    }

    & form {
      display: flex;
      flex-wrap: wrap;
      gap: 15px;
      margin-top: 15px;

      & .form-check {
        width: 150px;
        padding: 20px 10px;
        box-shadow: 0px 0px 48px #00000008;
        border: 0.5px solid #F6491E;
        border-radius: 20px;
        position: relative;
        margin-right: 0;
        cursor: pointer;

        @include responsive(phone) {
          flex: 0 0 29%;
        }

        & input {
          display: none;

          &:checked {
            background-color: #F6491E;
            border-color: #F6491E;
          }
        }

        & input:checked+label {
          background-color: #F6491E;
          border-color: #F6491E;
          color: #fff;

          @include responsive(phone) {
            font-size: 14px;
          }
        }

        & label {
          cursor: pointer;
          width: 100%;
          position: absolute;
          height: 100%;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 20px;

          @include responsive(phone) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.react-calendar {
  width: 100% !important;
  max-width: 100% !important;
  background: white;
  border: none !important;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}

.react-calendar__month-view {
  padding-bottom: 20px;
  position: relative;
}

.react-calendar__navigation button {
  font-size: 28px;

  @include responsive(phone) {
    font-size: 16px;
  }
}

.react-calendar__month-view__weekdays__weekday {
  font: normal normal 600 18px Source Sans Pro;
  letter-spacing: 0.36px;
  color: #110403;
  opacity: 0.38;
  text-decoration: none;

  @include responsive(phone) {
    font: normal normal 600 12px Source Sans Pro;
  }

  & abbr {
    text-decoration: none !important;
  }
}

.react-calendar__tile {
  &--now {
    background: none !important;
  }

  &--active {
    background: none !important;
    color: #F6491E !important;

    & abbr {
      width: 35px;
      height: 35px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      border-radius: 50%;
      border: 1px solid #F6491E;
    }
  }

  & abbr {
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;

    @include responsive(phone) {
      font-size: 12px;
    }
  }
}

.chooseLocation {
  & .locationItem {
    margin-bottom: 15px;
    width: 100%;
    position: relative;
    cursor: pointer;

    input[type="radio"] {
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;
      cursor: pointer;

      &:checked+.liwrap {
        border-color: #F6491E;
      }

      &:checked+.liwrap {
        .liwLeft {
          i {
            color: #F6491E;
          }
        }
      }
    }

    & .liwrap {
      display: flex;
      flex-grow: 1;
      align-items: center;
      gap: 15px;
      box-shadow: 0px 0px 48px #00000008;
      border: 0.5px solid #A5A5A5;
      border-radius: 6px;
      opacity: 1;
      padding: 10px 10px;

      & .liwLeft {
        i {
          font-size: 20px;
        }
      }

      & .liwRt {
        span {
          font: normal normal normal 17px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 0.77;

          @include responsive(phone) {
            font-size: 14px;
          }
        }

        h3 {
          font: normal normal normal 21px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 1;
          margin-bottom: 0;

          @include responsive(phone) {
            font-size: 18px;
          }
        }
      }
    }
  }
}

.modal-header {
  border-bottom: none;
}

// Login page

.accRegistration {
  .accRow {
    margin: 0;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 48px #00000008;
    border-radius: 12px;
    opacity: 1;
    margin-top: -60px;
    position: relative;
  }

  & .colRgLeft {
    & .rgLeftWrap {
      position: relative;
      min-height: 600px;
      background: linear-gradient(178deg, #F77902 0%, #F64B02 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 48px #00000026;
      border-radius: 12px 0px 0px 12px;
      opacity: 1;
      background-size: 100%;
      height: 100%;
      overflow: hidden;

      @include responsive(tab-port) {
        border-radius: 10px 10px 0 0;
        min-height: 480px;
      }

      @include responsive(Exsmall) {
        min-height: 370px;
      }

      &::before {
        content: "";
        position: absolute;
        top: 40px;
        width: 100%;
        height: 100%;
        background-image: url(./images/graph.png);
        background-size: 100%;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        opacity: .3;
      }

      .rgLcon {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        padding: 35px 35px;

        .rgLHd {
          position: relative;
        }

        h2 {
          font: normal normal 200 38px/51px Source Sans Pro;
          letter-spacing: 0px;
          color: #FFFFFF;
          opacity: 1;

          span {
            display: block;
          }
        }

        .rgLimg {
          img {
            position: relative;
            width: 100%;
          }
        }
      }
    }
  }

  & .colRgRight {
    .rgRtWrap {
      height: 100%;
      padding: 35px 30px;

      .rgRtHd {
        h2 {
          font: normal normal 300 41px Source Sans Pro;
          letter-spacing: 0px;
          color: #F6491E;
          opacity: 1;
          margin: 0;

          @include responsive(phone) {
            font-size: 34px;
          }
        }

        span {
          font: normal normal 300 24px/33px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 1;

          @include responsive(phone) {
            font-size: 18px;
          }
        }
      }

      .forgetPass {
        p {
          text-align: right;
          color: #ff5572;
          // cursor: pointer;

          p {
            font: normal normal 300 17px Source Sans Pro;
            letter-spacing: 0px;
            color: #F6491E;
            opacity: 1;
            text-decoration: none;
          }
        }
      }

      .register-form {
        @include responsive(phone) {
          margin-top: 30px;
        }

        .noCode {
          text-align: left;

          p {
            margin: 0;
            margin-top: 20px;

            span {
              color: #FF5722;
              text-decoration: none;
              font-size: 23px;
              cursor: pointer;
            }
          }
        }

        .formWrap {
          &--item {
            display: flex;
            justify-content: center;
            gap: 10px;

            input {
              display: inline-block;
              width: 50px;
              height: 50px;
              text-align: center;

              @include responsive(phone) {
                width: 40px;
                height: 40px;
              }
            }
          }

        }
      }
    }
  }
}

.register-form {
  margin-top: 50px;

  label {
    font: normal normal 300 15px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 0.74;
    margin-bottom: 10px;
  }

  input {
    @include input;

    ::placeholder {
      color: #90A4AE;
    }
  }

  .btn {
    @include btn;

    @include responsive(phone) {
      padding: 10px;
      font: normal normal 600 15px/20px Segoe UI;
    }
  }

  .message {
    @include message;

    &.pptu {
      margin-top: 25px;
      font: normal normal 300 17px/26px Source Sans Pro;
      text-align: left;
      margin-bottom: 25px;

      a {
        display: block;
      }
    }

    a {
      text-decoration: none;
      color: #F6491E;
    }
  }
}

// confirm Booking

.confirmBooking {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  padding-bottom: 60px;

  &.confirmTab {
    padding-bottom: 0;
    background: #fff 0% 0% no-repeat padding-box;

    .cbRow {
      margin-top: 5px;
      position: relative;
      box-shadow: none;
      border-radius: 0;

      @include responsive(phone) {
        padding: 0;
      }

      .colLcb,
      .colRcb {
        @include responsive(phone) {
          padding: 0;
        }
      }
    }

    .payCalcRow {
      .calcPayment {
        .calcPart {
          padding: 0;
        }
      }

    }
  }

  .cbRow {
    margin: 0;
    padding: 20px;
    padding-top: 30px;
    background-color: #fff;
    margin-top: -60px;
    position: relative;
    box-shadow: 0px 0px 48px #00000008;
    border-radius: 12px;
  }

  .cbLeft {
    h2 {
      font: normal normal 200 39px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;

      @include responsive(phone) {
        font: normal normal 200 32px Source Sans Pro;
      }
    }

    .cbLcar {
      height: 380px;
      // display: flex;
      align-items: center;
      overflow: hidden;

      @include responsive(tab-port) {
        height: 100%;
      }

      img {
        max-width: 100%;
        margin-top: 20px;
        object-fit: cover;
        width: 514px;
      }
    }
  }

  .cbRight {
    .cbRHd {
      margin-bottom: 30px;

      @include responsive(phone) {
        margin-top: 20px;
      }

      span {
        font: normal normal normal 22px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 1;
        // color: #F64975;
        

        @include responsive(phone) {
          font: normal normal normal 18px Source Sans Pro;
        }

        @include responsive(Exsmall) {
          font: normal normal 300 16px Source Sans Pro;
        }
      }

      h2 {
        font: normal normal 300 31px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 1;

        @include responsive(phone) {
          font: normal normal 300 30px Source Sans Pro;
        }

        @include responsive(Exsmall) {
          font: normal normal 300 24px Source Sans Pro;
        }
      }
    }

    .bookingDetail {
      padding-left: 0;

      .bdItem {
        list-style: none;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        @include responsive(phone) {
          display: block;
        }

        .bdLeft {
          padding-right: 40px;
          width: 20%;
          max-width: 20%;

          @include responsive(phone) {
            width: 100%;
            max-width: 100%;
          }

          span {
            font: normal normal 300 17px Source Sans Pro;
            letter-spacing: 0px;
            color: #110403;
            opacity: 0.68;
          }
        }

        .bdRight {
          display: flex;
          align-items: center;

          @include responsive(phone) {
            margin-top: 8px;
          }

          .bdRicon {
            i {
              color: #F6491E;
              width: 15px;
              height: 15px;
            }

            .dot {
              background-color: #F6491E;
              width: 15px;
              height: 15px;
              border-radius: 50%;
              display: block;
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;

              &.withWhite {
                &::before {
                  background-color: #fff;
                  width: calc(100% - 6px);
                  height: calc(100% - 6px);
                  content: "";
                  position: absolute;
                  border-radius: 50%;
                }
              }
            }
          }

          .optedItem {
            padding-left: 20px;

            @include responsive(phone) {
              padding-left: 10px;
            }

            h3 {
              font: normal normal 600 28px Source Sans Pro;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;

              @include responsive(phone) {
                font: normal normal 600 20px Source Sans Pro;
              }
            }

            span {
              font: normal normal 300 20px Source Sans Pro;
              letter-spacing: 0px;
              color: #110403;
              opacity: 1;

              @include responsive(phone) {
                font: normal normal 300 16px Source Sans Pro;
              }
            }
          }
        }
      }
    }

    .totalHrs {
      h3 {
        font: normal normal normal 22px Source Sans Pro;
        letter-spacing: 0px;
        color: #F6491E;
        opacity: 1;

        i {
          margin-right: 5px;
        }
      }

      p {
        font: normal normal normal 15px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 1;
      }
    }
  }
}

.calcRow {
  margin: 0;
  margin-top: 20px !important;

  .pickLocation {
    position: relative;
    min-height: 430px;
    padding: 30px;
    padding-top: 15px;

    &::before {
      background: url(./images/bg-pattern2.png) 0 -15px;
      border-radius: 12px;
      background-size: 100%;
      height: 100%;
      width: 100%;
      overflow: hidden;
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      opacity: 0.4;
      z-index: 9;
      background-repeat: no-repeat;
      background-position: bottom;
    }

    &::after {
      background: linear-gradient(178deg, #F77902 0%, #F64B02 100%) 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 48px #00000012;
      border-radius: 12px;
      opacity: 1;
      background-size: cover;
      height: 100%;
      width: 100%;
      overflow: hidden;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
    }

    .plSpan {
      font: normal normal normal 23px/50px Source Sans Pro;
      letter-spacing: 0px;
      color: #FFFFFF;
      opacity: 1;
      position: relative;
      z-index: 9;

      i {
        color: #FFFFFF;
      }
    }

    .locName {
      margin-top: 10px;
      position: relative;
      z-index: 9;

      span {
        font: normal normal 600 22px Source Sans Pro;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 0.65;
      }

      h2 {
        font: normal normal 300 36px Source Sans Pro;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
      }
    }
  }

  .calcPart {
    padding: 30px;
    background-color: #fff;
    box-shadow: 0px 0px 48px #00000008;
    border-radius: 12px;
    opacity: 1;

    @include responsive(tab-port) {
      margin-top: 20px;
    }

    span {
      font: normal normal normal 18px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 0.74;
      display: block;
      margin-bottom: 10px;

      @include responsive(phone) {
        font: normal normal normal 14px Source Sans Pro;
      }
    }

    .promoInput {
      display: flex;
      justify-content: space-between;

      input {
        flex-grow: 1;
        background: #FCFCFC 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 2px #0000000A;
        border: 1px solid #E1E1E1;
        border-radius: 2px 0px 0px 2px;
        opacity: 1;
        padding-left: 15px;
        outline: 0;

        @include responsive(phone) {
          width: 100%;
          font: normal normal normal 14px Source Sans Pro;
        }
      }

      button {
        background: #F6491E 0% 0% no-repeat padding-box;
        box-shadow: 0px 0px 2px #0000000a;
        border: 1px solid #F6491E;
        border-radius: 0px 2px 2px 0px;
        opacity: 1;
        color: #FFFFFF;
        font-size: 14px;
        padding: 10px 30px;
      }
    }

    .orderSum {
      padding-top: 15px;

      @include responsive(phone) {
        padding-top: 15px;
        margin-bottom: 15px;
      }

      h2 {
        font: normal normal normal 28px/61px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 1;

        @include responsive(phone) {
          font: normal normal normal 24px/61px Source Sans Pro;
        }
      }

      .orders {
        .orderin {
          display: flex;
          align-items: center;
          justify-content: space-between;

          &.totalPrice {

            .ordItemName span,
            .itemPrice span {
              font: normal normal 600 20px/34px Source Sans Pro;
              letter-spacing: 0px;
              color: #110403;
              opacity: 1;

            }

            &.clrOrange {
              span {
                color: #F6491E;
              }
            }
          }

          .ordItemName {
            span {
              font: normal normal 300 20px/34px Source Sans Pro;
              letter-spacing: 0px;
              color: #110403;
              opacity: 1;
            }
          }

          .itemPrice {
            &.actualPrice {
              span {
                font: normal normal 600 20px/34px Source Sans Pro;
                letter-spacing: 0px;
                color: #110403;
                opacity: 1;
              }
            }

            span {
              font: normal normal 300 20px/34px Source Sans Pro;
              letter-spacing: 0px;
              color: #110403;
              opacity: 1;
            }
          }
        }
      }

      .proceedBtn {
        padding-top: 20px;
        border-top: 1px solid #0000000A;
        margin-top: 15px;

        button {
          @include btn;
          border-radius: 4px;
          width: auto;
          padding: 10px 30px;
          margin: auto;
          margin-right: 0;

          @include responsive(phone) {
            font: normal normal 600 14px/26px Segoe UI !important;
            padding: 5px 25px !important;
          }
        }
      }
    }
  }
}

// tab
.pmRow {
  padding: 30px;
  padding-top: 15px;
  position: relative;
  margin-top: -60px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  border-radius: 12px;
  opacity: 1;

  h2 {
    font: normal normal normal 28px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
    margin-bottom: 30px;
  }

  .nav-tabs {
    border: none;
    display: flex;
    gap: 10px;

    .nav-item {
      .nav-link {
        border: none;
        position: relative;
        cursor: pointer;
        font: normal normal 300 28px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 0.41;
        padding: 8px 15px;

        @include responsive(phone) {
          font: normal normal 300 18px Source Sans Pro;
        }

        &.active {
          position: relative;
          opacity: 1;
          color: #F6491E;

          &::before {
            position: absolute;
            width: 100%;
            height: 1px;
            background-color: #F6491E;
            content: "";
            left: 0;
            bottom: 0;
            z-index: 9;
          }
        }
      }
    }
  }

  .cardsCards {
    height: 16rem;
    overflow: hidden;
    overflow-y: auto;
  }

  .tab-content {
    .tab-pane {
      .paneWrap {
        margin-top: 50px;
      }

      &.active {}

      .pmLeft {
        padding-right: 30px;

        label {
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 10px;
          background: #FFFFFF 0% 0% no-repeat padding-box;
          border: 0.5px solid #ffffff;
          margin-bottom: 20px;

          &.bkCredHd {
            border-bottom: 1px solid #c8c7c7;
            margin-bottom: 20px;
            padding-bottom: 5px !important;

            span {
              font: normal normal 300 18px Source Sans Pro;
              letter-spacing: 0px;
              color: #110403;
            }
          }

          &.active {
            background: #FFFFFF 0% 0% no-repeat padding-box;
            box-shadow: 0px 0px 48px #00000008;
            border: 0.5px solid #F6491E;
            border-radius: 12px;
            opacity: 1;
          }

          input {
            accent-color: #F6491E;

            &:checked {
              background-color: #F6491E;
              border-color: #F6491E;
            }
          }

          .ptBank {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 15px;
            flex: 1;
            padding-left: 20px;

            .bankNm {
              flex-basis: calc(40%);

              &.nmbk {
                flex-basis: calc(40% + 40px);
              }

              h3 {
                margin-bottom: 0;
                font: normal normal 600 20px/31px Source Sans Pro;
                letter-spacing: 0px;
                color: #110403;

                @include responsive(phone) {
                  font: normal normal 600 18px Source Sans Pro;
                }
              }

              span {
                font: normal normal 300 18px Source Sans Pro;
                letter-spacing: 0px;
                color: #110403;
                opacity: 1;

                @include responsive(phone) {
                  font: normal normal 300 16px Source Sans Pro;
                }
              }
            }

            .nameOnCard {}

            .expires {}
          }
        }
      }
    }
  }
}

.orderSy {
  .calcRow {
    margin: 0;
  }

  .calcPart {
    box-shadow: none;

    @include responsive(phone) {
      padding: 0;
    }

    @include responsive(tab-port) {
      margin-top: 20px;
    }

    .orderSum {
      padding-top: 0;

      @include responsive(phone) {
        padding-top: 15px;
      }

      h2 {
        font: normal normal normal 28px Source Sans Pro;

        @include responsive(phone) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.addCardBtn {
  margin-top: 30px;

  .acBtn {
    @include acBtn;
  }
}

.cardAccept {
  margin-top: 40px;

  p {
    margin-bottom: 0;
    font: normal normal 200 18px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
  }

  .cards {
    img {
      max-width: 100%;
    }
  }
}

.paymentRow {
  background-color: #FFFFFF;

  .procPaybtn {
    display: flex;
    align-items: center;
    justify-content: end;
    padding-top: 25px;
    margin-top: 15px;
    border-top: 1px solid #e0e0e0;

    .payBtn {
      @include btn;
      width: auto;
      border-radius: 4px;
    }
  }
}

.calcPayment {
  margin-top: 0 !important;

  .calcPart {
    box-shadow: none;

    @include responsive(tab-port) {
      margin-top: 20px;
    }

    .orderSum {
      padding-top: 0;

      @include responsive(phone) {
        padding-top: 15px;
      }

      h2 {
        font: normal normal normal 28px Source Sans Pro;
        margin-bottom: 20px;

        @include responsive(phone) {
          margin-bottom: 15px;
        }
      }
    }
  }
}

.payCalcRow {
  border-top: 1px solid #e4e4e4;
  padding-top: 25px;
  background-color: #FFFFFF;

  .colPayLeft {
    padding-right: 70px;

    h2 {
      margin-bottom: 0;
    }

    .payLin {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 48px #00000008;
      border: 0.5px solid #BBBBBB;
      border-radius: 12px;
      opacity: 1;
      margin-top: 20px;

      @include responsive(phone) {
        padding: 15px;
      }

      .pllleft {
        span {
          font: normal normal 300 23px Source Sans Pro;
          letter-spacing: 0px;
          color: #F6491E;
          opacity: 1;

          @include responsive(phone) {
            font: normal normal 300 18px Source Sans Pro;
          }
        }
      }

      .pllrt {
        text-align: right;

        img {
          width: 40px;
        }

        .accNo h2 {
          font: normal normal normal 31px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 1;
          margin-top: 10px;

          @include responsive(Exsmall) {
            font: normal normal normal 16px Source Sans Pro;
          }
        }
      }
    }
  }
}

.payMethods {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  padding-bottom: 60px;
}

.thankYouDec {
  text-align: center;

  h2 {
    font: normal normal 300 41px Source Sans Pro;
    letter-spacing: 0px;
    color: #F6491E;
    opacity: 1;
    margin-bottom: 10px;
  }

  p {
    text-align: center;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
    font-size: 24px;

    span {
      font-weight: 700;
      display: block;
    }
  }

  .bookDetail {
    display: flex;
    justify-content: center;
  }

  .bookDtlbtn {
    @include btn;
    width: auto;
    padding: 15px 30px;
    margin-top: 15px;
    border-radius: 4px;
  }
}

.confirmBooking .cbRow.thankRow {
  justify-content: center;
  margin-bottom: 60px;
  align-items: center;
  padding: 20px;
  padding-top: 20px;
  padding-bottom: 30px;
}

// Shop

.shopRow {
  margin-top: -60px;
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  border-radius: 12px;
  opacity: 1;

  .shopHd {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #F6491E 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 48px #00000026;
    border-radius: 12px 12px 0px 0px;
    padding: 30px;
    opacity: 1;

    @include responsive(phone) {
      flex-direction: column;
    }

    .shopHdLeft {
      h2 {
        font: normal normal 200 39px/61px Source Sans Pro;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 1;
        margin-bottom: 0;

        @include responsive(phone) {
          font-size: 34px;
        }
      }

      @include responsive(phone) {
        margin-bottom: 20px;
      }
    }

    .shopHdRt {
      gap: 100px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include responsive(phone) {
        gap: 60px;
      }

      .shopRtItem {
        span {
          font: normal normal 300 20px Source Sans Pro;
          letter-spacing: 0px;
          color: #FFFFFF;
          opacity: 0.68;
          display: flex;
          align-items: center;
          margin-left: 4px;

          @include responsive(phone) {
            font: normal normal 300 16px Source Sans Pro;
          }

          i {
            margin-right: 6px;
          }
        }

        .customs_selects {
          background: #F6491E 0% 0% no-repeat padding-box;

          .customs_sel_box {
            font: normal normal 600 24px Source Sans Pro;
            letter-spacing: 0px;
            color: #FFFFFF;
            opacity: 1;
            background: #F6491E 0% 0% no-repeat padding-box;
            border: none;
            margin-top: 5px;
            outline: 0;

            @include responsive(phone) {
              font: normal normal 600 18px Source Sans Pro;
            }
          }
        }
      }
    }
  }
}

.shopList {
  margin: 0;
}

.productItem {
  padding: 30px;
  margin-top: 20px;
  cursor: pointer;

  .prodThumb {
    height: 150px;
    text-align: center;

    img {
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .prodDetail {
    margin-top: 20px;

    .review {
      i {
        color: #FFD816;
      }
    }

    .prodName {
      font: normal normal 300 17px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;
      word-break: break-all;
    }

    .prodPrice {
      font: normal normal normal 30px Source Sans Pro;
      letter-spacing: 0px;
      color: #F6491E;
      opacity: 1;
    }
  }
}

.shopWrapper {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  padding-bottom: 60px;
}

.page {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 40px 40px;
  gap: 10px;

  &-item {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #fff;
    border-radius: 50%;
    cursor: pointer;

    &.active {
      border: 1px solid #F6491E;
      border-radius: 50%;
      background-color: #F6491E;
      color: #FFFFFF;
    }
  }
}

.itemProd {
  margin-bottom: 20px;

  .productBig {
    height: 300px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.navThumb {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  height: 70px;

  @include responsive(phone) {
    height: 40px;
    width: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}

.shopDetailWrapper {
  .shopDetailRow {
    padding: 30px;
    align-items: center;
    position: relative;
    margin: 0;
    margin-top: -60px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 48px #00000008;
    border-radius: 12px;
    opacity: 1;
  }
}

.psdin {
  margin-top: 20px;
}

.psdRow {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  span {
    &:first-child {
      font: normal normal 300 20px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 0.91;
      flex: 0 0 40%;
    }

    &:last-child {
      flex: 0 0 50%;
      font-weight: 600;
    }
  }
}

.sppageBtns {
  display: flex;
  gap: 15px;
  align-items: center;

  .addTocartBtn {
    padding: 10px 25px;
    color: #fff;
    background: #110403 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    font: normal normal 600 16px Segoe UI;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
    border: none;
    width: 170px;
  }

  .buyNowBtn {
    padding: 10px 25px;
    color: #fff;
    background: #F6491E 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    font: normal normal 600 16px Segoe UI;
    letter-spacing: 0px;
    text-transform: uppercase;
    opacity: 1;
    border: none;
    width: 170px;
  }
}

.prodDetailDec {
  padding-left: 60px;

  @include responsive(tab-port) {
    padding-left: 0;
    margin-top: 40px;
  }

  span {
    font: normal normal 300 17px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
  }

  h1 {
    font: normal normal 300 31px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
    margin-bottom: 0;

    @include responsive(tab-port) {
      margin-bottom: 5px;
      margin-top: 5px;
    }
  }

  .price {
    margin-top: 15px;

    &.proPrice {
      h2 {
        color: #F6491E;
      }
    }

    @include responsive(tab-port) {
      font: normal normal 600 34px Segoe UI;
    }

    h2 {
      font: normal normal 600 37px Segoe UI;
      letter-spacing: 0px;
      color: #110403;
      text-transform: uppercase;
      opacity: 1;
    }
  }

  .prodSingleDesc {
    margin: 30px 0;

    h3 {
      font: normal normal normal 22px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;
    }
  }

  .importantNote {
    margin-top: 50px;

    p {
      font: normal normal normal 17px/51px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;
      margin-bottom: 0;

      i {
        margin-right: 8px;
      }
    }
  }

  &.prodDec {
    padding-left: 0;
  }
}

.review {
  span {
    font: normal normal normal 18px Source Sans Pro;

    i {
      color: #FFD816;
    }

    .r-count {
      color: #F6491E;
      display: inline-block;
      padding-left: 10px;
    }
  }
}

.shopDetailPage {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-bottom: 60px;
}

.wrapForReview {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  border-radius: 12px;
  opacity: 1;
  margin-top: 20px;

  .cusReview {
    padding: 30px;
  }
}

// product booking
.productConfirmBkg {

  background: #F6F6F6 0% 0% no-repeat padding-box;

  @include responsive(tab-port) {
    padding: 0 15px 30px;

    .pcbColL,
    .pcbColR {
      padding: 0;
    }

    .prodDetailDec.prodDec {
      margin-top: 10px;
    }
  }

  padding-bottom: 60px;
}

.pl-0 {
  padding-left: 0 !important;

  @include responsive(tab-port) {
    padding-right: 0 !important;
  }
}

.pr-0 {
  padding-right: 0 !important;

  @include responsive(tab-port) {
    padding-left: 0 !important;
  }
}

.pcbRow {
  align-items: center;
  position: relative;
  margin: 0;
  margin-top: -60px !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  opacity: 1;
  border-radius: 12px;
  padding: 30px;

  .pcbLimg {
    height: 300px;
    padding: 30px;

    img {
      width: 100%;
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.countAlter {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 20px;
 .reduce_button{
   background: none;
   border: none;
   cursor: pointer;
 }

  span {
    font-size: 30px;
    
    &.otrCircle {
      width: 40px;
      display: inline-flex;
      height: 40px;
      border: 1px solid #000;
      text-align: center;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      cursor: pointer;
      font-size: 32px;
    }

  }
}

.ordersView {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-bottom: 60px;

  h1 {
    font: normal normal 200 39px/61px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
    margin-bottom: 30px;
  }
}

.orderItem {
  border-bottom: 1px solid #eeecec;
  margin-bottom: 30px;

  &:last-child {
    border-bottom: none;
    margin-bottom: 0;

    article {
      padding-bottom: 0;
    }
  }

  article {
    padding-bottom: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    align-items: center;

    @include responsive(tab-port) {
      flex-direction: column;
    }

    #product {
      &--image {
        flex-grow: 2;

        .proImg {
          width: 150px;
          height: 150px;
          margin: auto;
          flex: 0 0 150px;

          img {
            width: 100%;
            max-width: 100%;
            object-fit: contain;
            flex: 0 0 100%;
            height: 100%;
          }
        }
      }

      &--name {
        flex-grow: 5;
        text-align: left;

        @include responsive(tab-port) {
          margin-top: 10px;
          text-align: center;
        }

        h2 {
          font: normal normal normal 24px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 1;
          margin-bottom: 0;
        }

        .orderno {
          font: normal normal normal 20px Source Sans Pro;
          letter-spacing: 0px;
          color: #F6491E;
          opacity: 1;
        }

        .proName {
          .orderDate {
            font: normal normal 300 20px Source Sans Pro;
            padding-top: 10px;
            display: inline-block;

            &.cancel {
              font: normal normal 300 20px Source Sans Pro;
              letter-spacing: 0px;
              color: #FF1200;
              opacity: 1;
            }
          }
        }
      }

      &--rating {
        flex-grow: 1;

        @include responsive(tab-port) {
          margin-top: 20px;
        }

        .proRating {
          p {
            font: normal normal normal 20px/28px Source Sans Pro;
            letter-spacing: 0px;
            color: #110403;
            opacity: 1;

            @include responsive(tab-port) {
              margin-bottom: 5px;
            }
          }

          .stars {
            display: flex;
            gap: 5px;
            align-items: center;
            justify-content: center;

            span {
              font-size: 40px;
              color: #F9B10A;

              @include responsive(tab-port) {
                font-size: 20px;
              }
            }
          }
        }
      }
    }
  }
}

.orderTrack {
  .trackBtn {
    @include btn;
    border-radius: 4px;
    font: normal normal 600 19px/26px Segoe UI;
    letter-spacing: 0px;
    color: #FFFFFF;
    text-transform: uppercase;
  }
}

.orderWrapper {
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  border-radius: 12px;
  opacity: 1;
  padding: 30px;
  margin-top: -60px;
}

.orderSRow {
  position: relative;
  padding: 30px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  opacity: 1;
  border-radius: 12px;
  margin-bottom: -210px;

  .cbLeft {
    height: 250px;

    @include responsive(tab-port) {
      height: 170px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}

.odHd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h1 {
    font: normal normal 300 28px/61px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;

    @include responsive(phone) {
      margin-bottom: 0;
    }

    @include responsive(Exsmall) {
      font-size: 24px;
    }
  }

  .report {
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    padding: 10px 20px;
    background: #EFEFEF 0% 0% no-repeat padding-box;
    border-radius: 30px;
    opacity: 1;

    span {
      font: normal normal normal 14px/30px Source Sans Pro;
      letter-spacing: 0px;
      color: #2D2D2D;
      opacity: 1;

      i {
        color: #000;
      }
    }
  }
}

.orderDetailRt {
  @include responsive(tab-port) {
    margin-top: 30px;
  }

  .orderDate {
    margin-bottom: 30px;

    span {
      display: block;

      &.ordId {
        font: normal normal normal 20px Source Sans Pro;
        letter-spacing: 0px;
        color: #F6491E;
        opacity: 1;
        margin-bottom: 5px;
      }

      &.ordDt {
        font: normal normal 300 20px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 0.68;
      }
    }
  }

  .orderedProduct {
    h2 {
      text-align: left;
      font: normal normal 300 30px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;

      span {
        font: normal normal 300 17px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 1;
        display: block;
        margin-bottom: 5px;
      }
    }
  }
}

.orderSingleRow {
  align-items: center;
}

.orderPayment {
  padding-top: 75px;
  background: #F6F6F6 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-bottom: 60px;

  .container {
    @include responsive(phone) {
      padding: 0 15px !important;
    }
  }
}

.opRow {
  margin: 0 !important;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  opacity: 1;
  border-radius: 12px;
  padding: 30px;
  align-items: center;

  @include responsive(tab-port) {
    margin-top: 0px !important;
  }

  .colOpLt,
  .colOpRt {
    padding: 0;

    @include responsive(phone) {
      margin-top: 20px;
    }
  }
}

.item-deliver {
  &--edit {
    display: flex;
    align-items: center;

    span {
      font: normal normal 300 15px Source Sans Pro;
      letter-spacing: 0px;
      color: #F6491E;
      opacity: 1;
      display: inline-block;

      &.editBtn {
        font: normal normal normal 14px/22px Source Sans Pro;
        letter-spacing: 0px;
        color: #2D2D2D;
        opacity: 1;
        background: #EFEFEF 0% 0% no-repeat padding-box;
        border-radius: 16px;
        opacity: 1;
        padding: 5px 15px;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }

  &--location {
    display: flex;
    align-items: center;
    margin: 10px 0 20px;

    &--icon {
      i {
        color: #F6491E;
      }
    }

    &--locname {
      padding-left: 15px;

      h2 {
        font: normal normal 600 28px Source Sans Pro;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        margin-bottom: 0;

        @include responsive(phone) {
          font: normal normal 600 24px Source Sans Pro;
        }

        span {
          font: normal normal 300 20px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 1;
          display: block;
        }
      }
    }
  }

  &--cancel {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
    align-items: center;

    .cancelBtn {
      @include btn;
      flex-grow: 1;
      width: auto;
      border-radius: 4px;
      cursor: pointer;

      @include responsive(phone) {
        padding: 5px 10px;
        font-size: 14px;
      }
    }

    a {
      flex-grow: 1;
      text-align: left;
      padding-left: 40px;
      text-decoration: underline;
      font: normal normal 300 20px/34px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;
    }
  }
}

.colOpRt {
  .calcRow {
    margin-top: 0 !important;

    .calcPart {
      padding: 0;
      background-color: #fff;
      box-shadow: none;
      border-radius: 12px;
      opacity: 1;

      .orderSum {
        padding-top: 0 !important;

        h2 {
          font: normal normal normal 28px Source Sans Pro;
          margin-bottom: 20px;

          @include responsive(phone) {
            font: normal normal normal 24px Source Sans Pro;
          }
        }
      }
    }
  }
}

.paidCred {
  padding-top: 25px;
  border-top: 1px solid #dedede;
  margin-top: 10px;
  display: flex;
  align-items: end;
  justify-content: end;
  flex-direction: column;

  @include responsive(phone) {
    justify-content: flex-start;
    flex-direction: unset;
  }

  .paidviaCred {
    display: inline-flex;
    align-items: center;
    font: normal normal 300 20px/34px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;

    .pcredwrap {
      display: flex;
      align-items: center;

      .refundMsg {
        .refifpaid {
          display: flex;
          align-items: center;
        }

        span {
          width: 30px;
          height: 30px;
          color: #fff;
          display: flex;
          align-items: center;
          justify-content: center;
          background: #1F8722 0% 0% no-repeat padding-box;
          border-radius: 50%;
          margin-bottom: 0;
          padding: 10px;
          font-size: 15px;
          margin-right: 10px;
          margin-bottom: 0;
        }

        p {
          margin: 0;
          margin-left: 40px;
          font: normal normal 300 16px Source Sans Pro;
          letter-spacing: 0px;
          color: #FF1200;
          opacity: 1;
        }
      }
    }

  }
}

.oiLeft {

  &.orderCancel,
  &.orderDelivered {
    background-color: #F61E1E;

    .alertOrder {
      display: flex;
      align-items: center;

      .cancelDate {
        display: block;
        font: normal normal 300 16px/23px Source Sans Pro;
        letter-spacing: 0px;
        color: #FFFFFF;
        opacity: 0.68;
      }
    }

    &.orderDelivered {
      background-color: #F6491E;
    }
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  background-color:#F6491E;
  box-shadow: 0px 0px 48px #00000008;
  border-radius: 4px;
  opacity: 1;
  margin-top: 30px;

  @include responsive(tab-port) {
    flex-direction: column;
  }

  span {
    font: normal normal 300 21px/23px Source Sans Pro;
    letter-spacing: 0px;
    color: #FFFFFF;
    opacity: 1;

    i {
      color: #fff;
      margin-right: 10px;
    }
  }

  .oiBtn {
    padding: 10px 25px;
    color: #F6491E;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
    border: none;

    @include responsive(tab-port) {
      margin-top: 20px;
    }
  }
}

.WrapperBnr {
  .bnrCon {
    @include responsive(phone) {
      padding: 0 15px !important;
    }
  }

  &.confirmBooking {
    padding-bottom: 0;
    background: none;
    margin-bottom: -210px;
  }
}

.rideDetail {
  padding-top: 70px;
  background: #F6F6F6 0% 0% no-repeat padding-box;
  padding-bottom: 60px;

  .container {
    @include responsive(phone) {
      padding: 0 15px !important;
    }
  }

  .calcRow {
    margin-top: 0 !important;
  }

  .opRow {
    margin-top: 0 !important;

    @include responsive(Exsmall) {
      margin-top: 60px !important;
    }
  }
}

.cancelRide {
  text-align: right;
}

.cancelBtn {
  @include btn;
  width: auto;
  margin: auto;
  margin-right: 0;
  border-radius: 4px;

  @include responsive(phone) {
    padding: 5px 10px;
    font-size: 14px;
  }
}

.cofirmBookingOnBnr {
  .cbRow {
    margin-top: 0;
  }
}

.suppPop {
  text-align: center;
  padding: 30px 0;

  .suppCon {
    text-align: center;
    padding-bottom: 25px;

    span {
      font: normal normal 300 22px/31px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;
    }
  }

  .supportBtn {
    @include btn;
    width: auto;
    border-radius: 4px;
    padding: 10px 30px !important;
    margin: auto;
    font: normal normal 600 16px/26px Segoe UI;
  }
}

.ContactUs.accRegistration {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  padding-bottom: 60px;
  opacity: 1;

  .contactRow {
    justify-content: center;
    max-width: 70%;
    margin: auto;
    margin-top: -60px;

    @include responsive(phone) {
      max-width: 100%;
    }

    .FormRow {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      @include responsive(phone) {
        flex-direction: column;
      }

      >div:nth-child(2n+1) {
        padding-right: 10px;

        @include responsive(phone) {
          padding-right: 0;
        }
      }

      >div:nth-child(2n+2) {
        padding-left: 10px;

        @include responsive(phone) {
          padding-left: 0;
        }
      }
    }

    .form-controller {
      select {
        width: 100%;
        border: 0;
        margin: 0 0 15px;
        padding: 15px;
        outline: 0;
        font-size: 15px;
        box-sizing: border-box;
        background-color: #FCFCFC !important;
        box-shadow: 0px 0px 2px #0000000a;
        border: 1px solid #E1E1E1;
        border-radius: 4px;

        // 
        background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
        -webkit-appearance: none;
        background-position-x: calc(100% - 20px);
        background-size: 10px;
      }

      textarea {
        width: 100%;
        border: 0;
        margin: 0 0 15px;
        padding: 15px;
        outline: 0;
        font-size: 15px;
        box-sizing: border-box;
        background-color: #FCFCFC !important;
        box-shadow: 0px 0px 2px #0000000a;
        border: 1px solid #E1E1E1;
        border-radius: 4px;
      }

    }

    .contactBtn {
      width: auto;
      margin: auto;
      margin-right: 0;
      padding: 10px 40px;
    }

    .register-form {
      margin-top: 0;
    }
  }
}


// cart page
.table-col-3 {

  .table-sticky-header>div:first-child,
  tbody th,
  thead th {
    width: 60%;
    min-width: 200px;
  }

  .table-sticky-header>div:not(:first-child),
  tbody td {
    width: 20%;
    min-width: 100px;
  }

  .table-sticky table,
  .table-sticky-header {
    width: calc(100% - 1px);
    margin: auto;
  }

  .table-sticky-header {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

.product {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 40px;

  &--img {
    flex-grow: 1;
    width: 120px;
    display: flex;
    height: 120px;

    @include responsive(phone) {
      height: 100px;
    }

    img {
      width: 60%;
      max-width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &--name {
    flex-grow: 1;
    text-align: left;

    h2 {
      font: normal normal 300 18px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;
      margin-bottom: 0;

      @include responsive(phone) {
        font: normal normal 300 16px Source Sans Pro;
      }
    }

    .stockInfo {
      span {
        font: normal normal normal 16px Source Sans Pro;
        letter-spacing: 0px;
        color: #F6491E;
        opacity: 1;
      }
    }
  }
}

.quantityAlter {
  .countAlter {
    margin-top: 0;
    gap: 8px;

    span {
      font-size: 20px;

      &.otrCircle {
        width: 30px;
        display: inline-flex;
        height: 30px;
        opacity: .8;

        &.minus {
          opacity: .8;
        }

        &.plus {
          opacity: .8;
        }
      }
    }
  }
}

.priceForProduct {
  text-align: left;

  span {
    display: block;
    font: normal normal 300 22px/34px Source Sans Pro;

    &.deliveryfee {
      font: normal normal 300 16px/34px Source Sans Pro;
      letter-spacing: 0px;
      color: #F6491E;
    }
  }
}

.tbHd {
  text-align: left;
}

.subTotal {
  text-align: left;

  span {
    font: normal normal 600 22px/34px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
  }
}

.trash {
  span {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #9c9c9c;
    cursor: pointer;
    color: #fff;
  }
}

.cartTableBody {
  th {
    border-left: 0 !important;
    border-right: 0 !important;
    font: normal normal 300 20px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
  }

  .cartItem {
    td {
      border-left: 0 !important;
      border-right: 0 !important;
    }
  }
}

.cartTable {
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  border-radius: 12px;
  margin-top: -60px;
  padding: 30px;
  padding-bottom: 0;

  table {
    --bs-table-striped-bg: rgb(224 224 224 / 5%);
  }
}

.cartWrap {
  background: #F6F6F6 0% 0% no-repeat padding-box;
}

.cartHd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;

  h1 {
    font: normal normal 200 39px/61px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;

    @include responsive(phone) {
      font: normal normal 200 30px Source Sans Pro;
    }
  }

  .cartRight {
    span {
      font: normal normal 300 25px/61px Source Sans Pro;
      letter-spacing: 0px;
      color: #F6491E;
      opacity: 1;

      @include responsive(phone) {
        font: normal normal 300 14px/61px Source Sans Pro;
      }
    }
  }
}

.itemNoStock {
  opacity: 0.4;
}

.payOrder {
  padding-top: 20px;
}

.calcPart {
  &.noArt {
    padding-right: 100px;
    box-shadow: none;
    padding-left: 0;

    @include responsive(phone) {
      padding: 0;
    }
  }

  &.noEff {
    padding: 0;
    box-shadow: none;
  }
}

.cartMsg {
  padding-top: 30px;

  span {
    font: normal normal normal 17px/51px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;

    i {
      margin-right: 6px;
    }
  }
}

.cartTableBody th {
  @include responsive(phone) {
    padding: 8px !important;
    font-size: 16px;
  }
}

// ride list
.ridesList {
  tbody {
    td {
      vertical-align: middle;
      padding: 30px 0;
    }

    tr {

      &:last-child {
        border-color: white;
        
      }
    }
  }
}

.ride-Item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Trip {
  @include responsive(tab-port) {
    width: 220px;
  }

  .bdRight {
    display: flex;
    align-items: center;

    .bdRicon {
      i {
        color: #F6491E;
        width: 15px;
        height: 15px;
      }
    }

    .bdRicon {
      .dot {
        background-color: #F6491E;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        display: block;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;

        &.withWhite {
          &::before {
            background-color: #fff;
            width: calc(100% - 6px);
            height: calc(100% - 6px);
            content: "";
            position: absolute;
            border-radius: 50%;
          }
        }
      }
    }

    .optedItem {
      padding-left: 20px;

      label {
        display: block;
        font: normal normal 300 17px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 0.68;

        @include responsive(phone) {
          font: normal normal 300 14px Source Sans Pro;
        }
      }

      span {
        text-align: left;
        font: normal normal 300 20px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 0.68;

        @include responsive(phone) {
          font: normal normal 600 16px Source Sans Pro;
        }
      }

      h3 {
        font: normal normal 600 28px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 1;
        margin-bottom: 0;

        @include responsive(phone) {
          font: normal normal 600 20px Source Sans Pro;
        }
      }
    }
  }
}

.product--name {
  .ride {
    &--car {
      span {
        display: block;

        &.orderId {
          font: normal normal normal 20px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 1;

          @include responsive(phone) {
            font: normal normal normal 16px Source Sans Pro;
          }
        }

        &.orderName {
          font: normal normal normal 20px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 1;

          @include responsive(phone) {
            font: normal normal normal 16px Source Sans Pro;
          }
        }
      }
    }

    &--date {
      span {
        font: normal normal 300 20px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 0.68;
        display: inline-block;
        margin-top: 5px;

        @include responsive(phone) {
          font: normal normal normal 16px Source Sans Pro;
        }
      }
    }

    &--info {
      display: flex;
      align-items: center;
      margin-top: 30px;

      &--thumb {
        &__img {
          width: 60px;
          height: 60px;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 50%;
          }
        }
      }

      &--detail {
        h3 {
          font: normal normal normal 18px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 1;
          margin-bottom: 0;

          @include responsive(phone) {
            font: normal normal normal 16px Source Sans Pro;
          }
        }

        span {
          font: normal normal normal 16px Source Sans Pro;
          letter-spacing: 0px;
          color: #F6491E;
          opacity: 1;
          margin-bottom: 0;

          @include responsive(phone) {
            font: normal normal normal 14px Source Sans Pro;
          }
        }
      }

      &--status {
        display: flex;
        align-items: center;

        &__left {
          font: normal normal normal 16px Source Sans Pro;
          letter-spacing: 0px;
          color: #1F8722;
          opacity: 1;
          padding-right: 10px;
        }
      }
    }
  }
}

.usedHrs {
  @include responsive(tab-port) {
    width: 150px;
  }

  span {
    font: normal normal normal 22px Source Sans Pro;
    letter-spacing: 0px;
    color: #F6491E;
    opacity: 1;

    @include responsive(tab-port) {
      font: normal normal normal 18px Source Sans Pro;
    }
  }
}

.reviewStar {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 2px;

  i {
    color: #FEBA1B;
  }
}

.ridesWrapper {
  padding: 30px;
  padding-bottom: 0;
  background-color: #fff;
  position: relative;
  margin-top: -60px;
  border-radius: 12px;
  box-shadow: 0px 0px 48px #00000008;
}

.myRidesView {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  opacity: 1;
  padding-bottom: 60px;
}

.ridesHead {
  h1 {
    font: normal normal 200 39px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;

    @include responsive(tab-port) {
      font: normal normal 200 30px Source Sans Pro;
    }
  }
}

.cancelledRow {
  filter: opacity(0.4);
}

.colOptions {
  .reshedule{
    flex-direction: unset;
  }
  display: flex;
  flex-direction: column;
  .userCal {
    display: flex;
    align-items: center;

    @include responsive(tab-port) {
      flex-wrap: wrap;
    }

    gap:10px;

    .userLeft {
      width: 50px;
      height: 50px;
      overflow: hidden;
      display: block;
      border-radius: 50%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    .userRt {
      >span {
        display: block;

        &.usrNm {
          font: normal normal normal 24px Source Sans Pro;
          letter-spacing: 0px;
          color: #110403;
          opacity: 1;
        }

        &.usrInfo {
          font: normal normal normal 20px Source Sans Pro;
          letter-spacing: 0px;
          color: #F6491E;
          opacity: 1;
        }
      }
    }
  }
}

.connect {
  display: flex;
  align-items: center;
  margin-top: 15px;
  margin-left:20px;
  gap: 10px;

  .connWith {
    width: 30px;
    cursor: pointer;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    border-radius: 50%;
    background-color: #f6491e;

    i {
      color: #fff;
      transform: scaleX(-1);
    }
  }

  .arrival {
    span {
      background: #F6491E 0% 0% no-repeat padding-box;
      border-radius: 30px;
      opacity: 1;
      display: inline-block;
      padding: 10px 30px;
      color: #fff;
      font: normal normal normal 16px Source Sans Pro;
    }
  }
}

.colOpRt.withDetail {
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include responsive(Exsmall) {
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }

  .colOptions {
    @include responsive(Exsmall) {
      margin-bottom: 30px;
    }
  }
}

.locationRow {
  .google-map-code {
    padding: 0;
    margin-top: 40px;
  }
}

span.orderId {
  font: normal normal normal 20px Source Sans Pro !important;
  // color: #F6491E !important;
}

span.orderDate {
  font: normal normal 300 20px Source Sans Pro !important;
  color: #110403;
  opacity: 0.68 !important;
}

.rideInfo {
  padding-top: 15px;

  span {
    font: normal normal normal 20px Source Sans Pro;
    letter-spacing: 0px;
    color: #1F8722;
  }
}

.ratingReview {
  padding-top: 50px;
  padding-right: 70px;

  @include responsive(tab-port) {
    padding-right: 0;
  }

  .rating {
    h3 {
      font: normal normal normal 24px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;
      margin-bottom: 5px;
    }

    .ratingAlter {
      display: flex;
      align-items: center;
      gap: 15px;

      .ratingStars {
        display: flex;
        align-items: center;
        gap: 3px;

        span {
          color: #FEBA1B;
          font-size: 25px;
        }
      }

      .reviewEdit {
        button {
          font: normal normal normal 14px Source Sans Pro;
          letter-spacing: 0px;
          color: #2D2D2D;
          background: #EFEFEF 0% 0% no-repeat padding-box;
          border-radius: 30px;
          border: none;
          padding: 8px 15px;
        }
      }
    }

    .reviewDec {
      p {
        font: normal normal 300 20px/30px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 1;
        margin-bottom: 0;
        margin-top: 10px;
      }
    }
  }
}

.bdrTp {
  border-top: 1px solid #dedede;
  padding-top: 15px;
}

.tickOk {
  width: 30px;
  height: 30px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1F8722 0% 0% no-repeat padding-box;
  border-radius: 50%;
  margin-bottom: 0;
  padding: 10px;
  font-size: 15px;
  margin-right: 10px;
  margin-bottom: 0;

  i {
    color: #fff;
    font-size: 15px;
  }
}

.infoLine {
  display: flex;
  align-items: center;
}

.addReview {
  margin-top: 20px;

  label {
    font: normal normal 300 15px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 0.74;
    margin-bottom: 5px;
  }

  textarea {
    width: 100%;
    height: 112px;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    outline: 0;
    font-size: 15px;
    box-sizing: border-box;
    background-color: #FCFCFC !important;
    box-shadow: 0px 0px 2px #0000000a;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
  }
}

.tipPay {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;

  @include responsive(phone) {
    flex-wrap: wrap;
  }

  .tipItem {
    span {
      border: 1px solid #F6491E;
      border-radius: 4px;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 15px;
      color: #F6491E;
      cursor: pointer;
    }
    input{
      border: 1px solid #F6491E;
      border-radius: 4px;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px 15px;
      color: #F6491E;
      cursor: pointer;
      outline: none;
      width: 61%;
    }
  }
  .tipActiveItem {
    span {
      border: 4px solid #F6491E;
      border-radius: 4px;
      opacity: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 17px;
      color: #F6491E;
      cursor: pointer;
    }
  }
}

.customAmt {
  margin-top: 20px;

  label {
    font: normal normal 300 15px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 0.74;
    margin-bottom: 10px;
  }

  .cusInpBtn {
    display: flex;
    gap: 10px;
    width: 100%;

    @include responsive(phone) {
      width: 100%;
    }

    input {
      width: 100%;
      border: 0;
      margin: 0 0 15px;
      padding: 15px;
      outline: 0;
      font-size: 15px;
      box-sizing: border-box;
      background-color: #FCFCFC !important;
      box-shadow: 0px 0px 2px #0000000a;
      border: 1px solid #E1E1E1;
      border-radius: 4px;
      margin-bottom: 0;
    }

    button {
      border: none;
      cursor: pointer;
      background: #F6491E 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
      color: #fff;
      padding: 10px 45px;
    }
  }

}

.allRead {
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    font: normal normal normal 14px/30px Source Sans Pro;
    letter-spacing: 0px;
    color: #2D2D2D;
    opacity: 1;
    background: #EFEFEF 0% 0% no-repeat padding-box;
    border-radius: 30px;
    display: flex;
    padding: 5px 15px;
    cursor: pointer;
  }
}

.nvRow {
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 0;
  flex-wrap: wrap;
  border-bottom: 1px solid #e9e9e9;

  @include responsive(phone) {
    gap: 10px;
  }

  .nvIcon {
    .nvImg {
      width: 85px;
      height: 85px;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }

  .nvDec {
    h2 {
      font: normal normal normal 24px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;
      margin-bottom: 0;

      @include responsive(phone) {
        font: normal normal normal 20px Source Sans Pro;
        margin-bottom: 6px;
      }
    }

    span {
      &.nvId {
        font: normal normal normal 20px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 1;
        display: block;

        @include responsive(phone) {
          font: normal normal normal 16px Source Sans Pro;
        }
      }
      &.nv-gray {
        font: normal normal normal 20px Source Sans Pro;
        letter-spacing: 0px;
        color: #E5E5E5;
        opacity: 1;
        display: block;

        @include responsive(phone) {
          font: normal normal normal 16px Source Sans Pro;
        }
      }

      &.nvTime {
        font: normal normal 300 20px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 0.68;
        display: block;
        margin-top: 10px;

        @include responsive(phone) {
          font: normal normal normal 16x Source Sans Pro;
          margin-top: 4px;
        }
      }
    }
  }
}

.nvWrap {
  padding: 30px;
  margin-top: -60px;
  position: relative;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  opacity: 1;
  border-radius: 12px;
  padding-bottom: 0;
}

.notificationView {
  background: #F6F6F6 0% 0% no-repeat padding-box;
  padding-bottom: 60px;
}

a {
  text-decoration: none !important;

  span {
    color: #000;
  }
}

.expDateSelect .customs_selects {
  select {
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    outline: 0;
    font-size: 15px;
    box-sizing: border-box;
    background-color: #FCFCFC !important;
    box-shadow: 0px 0px 2px #0000000a;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
    background: url(http://cdn1.iconfinder.com/data/icons/cc_mono_icon_set/blacks/16x16/br_down.png) no-repeat right #ddd;
    -webkit-appearance: none;
    background-position-x: calc(100% - 20px);
    background-size: 10px;
  }
}

.addCardFrm {
  margin-top: 25px;
}

.frmCard {
  padding: 0 15px;
}

.expDateSelect {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 10px;

  .customs_selects {
    flex-grow: 1;
  }
}

/* ---- checkbox area ---- */
fieldset {
  cursor: pointer;

  label {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
}

.black-checkbox .fill-control-input:checked~.fill-control-indicator {
  background-color: #F6491E;
  border-color: #F6491E;
}

.fill-control-input {
  display: none;
}

.black-checkbox .fill-control-input:checked~.fill-control-indicator {
  background-color: #F6491E;
  border-color: #F6491E;
  background-size: 80%;
}

.fill-control-indicator {
  border-radius: 3px;
  display: inline-block;
  top: 4px;
  left: 0;
  width: 16px;
  height: 16px;
  border: 2px solid #aaa;
  transition: .3s;
  background: transperent;
  background-size: 0%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");
}

.fill-control-description {
  padding-left: 10px;
}

.proLeft {
  background-color: #F6491E;
  padding: 30px 30px !important;

  .menuList {
    &_single {
      margin-bottom: 15px;
      cursor: pointer;

      span {
        color: #fff;
        opacity: .68;
        font-size: 18px;
      }

      &.active {
        span {
          opacity: 1;
        }
      }

    }
  }
}

.contactDetail {
  margin-top: 50px;

  .cdItem {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #e7e7e7;

    @include responsive(phone) {
      flex-wrap: wrap;
    }

    span {
      flex-grow: 1;
      flex: 0 0 115px;
      opacity: .68;
    }

    h3 {
      margin-bottom: 0;
    }
  }
}

.profileHd {
  display: flex;
  align-items: center;
  gap: 15px;

  .profImg {
    width: 100px;
    height: 100px;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }

  .profRt {
    h2 {
      margin-bottom: 0;
    }

    .profNm {
      span {
        color: #F6491E;
        cursor: pointer;
      }
    }
  }
}

.editProfile{
  background-color: #F6F6F6;
}
.editProfileBtn{

  display: flex;
  justify-content: flex-end;

  span {
    display: inline-block;
    padding: 10px 30px;
    background-color: #e4e4e4;
    border-radius: 30px;
    cursor: pointer;

    @include responsive(phone) {
      margin-bottom: 40px;
    }
  }
}

.proRight {
  padding: 30px 30px !important;
}

.proLeft {
  padding-bottom: 170px !important;

  @include responsive(phone) {
    padding-bottom: 15px !important;
  }
}

.profileMainWrapper {
  display: flex;
  justify-content: center;

  .proRow {
    max-width: 80%;
    margin: 0 auto;
    position: relative;
    margin-top: -60px !important;
    background-color: #fff;
    border-radius: 12px;
    overflow: hidden;
    margin-bottom: 60px;
    flex: 0 0 80%;
    box-shadow: 0px 0px 48px #00000008;

    @include responsive(tab-port) {
      max-width: 100%;
      flex: 0 0 100%;
    }
  }
}

.loginPane {
  display: none;
}

.loginPane.active {
  display: block;
}

.paymt_in_top {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .icon {
    img {
      width: 50px;
    }
  }
}

.paymt_in_btm {
  display: flex;
  justify-content: space-between;
  align-items: end;
  margin-top: 15px;

  .accDetail {
    h2 {
      margin-bottom: 0;
    }

    .accText {
      span {
        opacity: .68;
      }
    }
  }

  .remove {
    span {
      text-decoration: none;
      color: #F6491E;
      font-size: 15px;
      cursor: pointer;
    }
  }
}

.paymt_wrap {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  border: 0.5px solid #BBBBBB;
  border-radius: 12px;
  opacity: 1;
  margin-top: 20px;
  padding: 15px;
}

.payType-item {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  border: 0.5px solid #BBBBBB;
  border-radius: 12px;
  padding: 20px;
  position: relative;

  &:not(:first-child) {
    margin-top: 20px;
  }

  .checked {
    position: absolute;
    right: 15px;

    input {
      accent-color: #F6491E;
    }
  }
}

label.paymt {
  width: 100%;
}

.addPayment {
  .addCardBtn {
    button {
      margin: auto;
    }
  }
}

.shppg_in_btm {
  display: flex;
  justify-content: flex-end;
  align-items: end;
  margin-top: 15px;
  gap: 10px;
}

.shipping_name {
  h2 {
    font-size: 25px;
    margin-bottom: 0;
    opacity: .68;
    font-weight: 100;
  }

  p {
    font-weight: 700;
  }
}

.remove {
  span {
    text-decoration: underline;
    color: #F6491E;
    font-size: 15px;
  }
}

.edit {
  span {
    text-decoration: underline;
    color: #191919 !important;
    font-size: 15px;
  }
}

.formIpt {
  position: relative;
  label {
    font: normal normal 300 15px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 0.74;
    margin-bottom: 10px;
  }

  input {
    width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    outline: 0;
    font-size: 15px;
    box-sizing: border-box;
    background-color: #FCFCFC !important;
    box-shadow: 0px 0px 2px #0000000a;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
  }
}

.addSaveBtn {
  button {
    width: 100%;
    background-color: #F6491E;
    color: #fff;
    padding: 15px 30px;
    border: none;
    margin-top: 20px;
    border-radius: 4px;
  }
}

.termWrap {
  padding: 30px;
  padding-top: 15px;
  position: relative;
  margin-top: -60px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  border-radius: 12px;
  opacity: 1;
  margin-bottom: 60px;

  h1 {
    font: normal normal 300 36px/61px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
  }

  h2 {
    font: normal normal normal 30px Source Sans Pro;
    letter-spacing: 0px;
    color: #F6491E;
    opacity: 1;
    margin-bottom: 5px;
  }

  p {
    font-size: 16px;
  }
}

.dropdown-toggle::after {
  margin-left: 0.255em;
  vertical-align: 0.18em !important;
}

@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.2rem !important;
    padding-left: 0.2rem !important;
  }

  .hiwDec {
    padding: 0 40px;
  }
}

span.smallH {
  text-transform: lowercase !important;
  color: #fff;
}

span.passwordToggleIcon {
  position: absolute;
  top: 50px;
  right: 15px;
  z-index: 9;
  cursor: pointer;
}

.register-form .form-controller {
  position: relative;
}

.menuNav img.logoBlk {
  display: none;
}

.menuNav.stickyMenu img.logoBlk {
  display: block !important;
}

.menuNav img.logoWht {
  display: block;
}

.menuNav.stickyMenu img.logoWht {
  display: none !important;
}

.liwLeft .checkmark {
  height: 25px;
  width: 25px;
  background-color: #eee;
  display: block;
  border-radius: 4px;
  position: relative;
}

.liwLeft .checkmark::after {
  display: block;
}

input:checked~.liwrap .liwLeft .checkmark::after {
  left: 9px;
  top: 4px;
  width: 7px;
  height: 14px;
  border: solid #fff;
  border-width: 0 3px 3px 0px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

input:checked~.liwrap .liwLeft .checkmark::after {
  content: "";
  position: absolute;
}

.liwLeft .checkmark {
  border-radius: 50%;
}

input:checked~.liwrap .liwLeft .checkmark {
  background-color: #f6491e !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


input[type=number] {
  -moz-appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.promoApplied {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fcfcfc;
  padding: 10px 15px;
  border: 1px solid #E1E1E1;

  .paLeft {
    display: flex;

    .patext {
      span {
        font: normal normal normal 18px Source Sans Pro;
        letter-spacing: 0px;
        color: #110403;
        opacity: 0.74;
        display: block;
        margin-bottom: 0;
        margin-left: 5px;
      }
    }
  }

  .paRt {
    span {
      color: #F6491E;
      margin-bottom: 0;
      cursor: pointer;
    }
  }
}



        input[type=number] {
          -moz-appearance: textfield !important;
        }
.epHd{
  h2{
    font: normal normal 200 32px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
    margin-bottom: 0;
  }
}
.ride--info.ep--info{
  margin-top: 15px !important;
}
.product--name .ep--info .ride--info--detail h3{
  font: normal normal normal 24px Source Sans Pro;
}
.epForm.register-form{
  margin-top: 15px !important;
}
.epForm.register-form input{
  padding: 8px 15px;
}
.epRow{
  justify-content: center;
  .epIn{
    margin-top: -60px;
    position: relative;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 48px #00000008;
    border-radius: 12px;
    padding: 30px;
  }
}
.register-form .btn.btn-save{
  padding: 8px;
  font: normal normal 600 16px/26px Segoe UI;
  margin-top: 26px;
  background-color: #F6491E;
}
.product--name.epDetail{
  margin-top: 40px;
}
.payType-item input{
  display: none;
}
.payType-item label{
  cursor: pointer;
}
h2.epHd{
  display: flex;
  align-items: center;
  gap:10px;
  span{
    img{
      width:20px;
    }
  }
}
.EditWrp{
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 48px #00000008;
  border: 0.5px solid #BBBBBB;
  border-radius: 12px;
  padding: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  h2{
    font: normal normal 300 19px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
    margin-bottom: 5px;
  }
  .EdtIn{
    span{
      font: normal normal 600 17px Source Sans Pro;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
  }  
}
.editBtn{
  font: normal normal 600 15px/20px Segoe UI;
  letter-spacing: 0px;
  color: #F6491E;
  text-transform: uppercase;
  opacity: 1;
  padding: 10px 30px;
  border: 1px solid #F6491E;
  border-radius: 4px;
  background-color: #fff;
  margin-top: 20px;
}
.ep-change{
  span{
    &.ep-change--sub{
      font: normal normal 300 15px/22px Source Sans Pro;
      letter-spacing: 0px;
      color: #110403;
      opacity: 1;
      display: block;
      margin-top: 8px;
      margin-left: 32px;
    }
  }
}
.footertemp{
  text-align: center;
  margin-top: 20px;
  span{
    color: #F6491E;
    cursor: pointer;
    span{
      color: #F6491E;
      cursor: pointer;
    }
  }
}
.formWrap--item.changeItemWrp input {
  display: inline-block;
  width: 64px;
  height: 50px;
  text-align: center;
}
.changeItemWrp{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.success_hd{
  text-align: center;
}
.epSuccess{
  min-height: 420px;
  display: flex;
  align-items: center;
}
.product--name.epDetail.successView {
  margin-top: 0;
}
.success-dec{
  text-align: center;
  margin-top: 15px;
  h2{
    font: normal normal 200 39px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    margin-bottom: 15px;
  }
  p{
    font: normal normal 300 15px Source Sans Pro;
    letter-spacing: 0px;
    color: #140400;
    opacity: 1;
  }
}
.vfyTxt{
  color: #F6491E;
}
.faqsSec.faqPgIn{
  background: #fff 0% 0% no-repeat padding-box;
}
.faqSecWrp{
  background: #F6F6F6 0% 0% no-repeat padding-box;
  padding-bottom: 60px;
}
.termWrap.faqDetailRev{
  margin-bottom: 0;
}
.faqsSec.faqPgIn .accordion-item{
  border-bottom: 1px solid #d5d5d5;
}
.itemAccord button.accordion-button::before {
  position: absolute;
  top: 50%;
  content: "\f107";
  font-family: "Font Awesome 5 Pro";
  font-weight: 100;
  right: 0;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cartLink.nav-link {
  display: flex;
  gap: 5px;
}
.stickyMenu svg path{
  fill: #000 !important;
}
.stickyMenu .navbar-light .navbar-toggler-icon{
  background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%280, 0, 0, 0.55%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e") !important
}

.checkBox-agree{
  label{
    display: flex;
    align-items: center;
    gap: 6px;
  }
}
.addNwAdrsHd{
  h2{
    margin-bottom: 0;
    font: normal normal 200 39px Source Sans Pro;
    letter-spacing: 0px;
    color: #110403;
    opacity: 1;
  }
}
.addNewForm{
  margin-top: 30px;
}
.form-group.checkBox-agree input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.form-group.checkBox-agree label {
  position: relative;
  cursor: pointer;
  font: normal normal 300 14px Source Sans Pro;
  letter-spacing: 0px;
  color: #110403;
  margin-bottom: 20px;
}

.form-group.checkBox-agree label:before {
  content:'';
  -webkit-appearance: none;
  background-color: #F6491E;
  border: 2px solid #F6491E;
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
  border-radius: 2px;
}

.form-group.checkBox-agree input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 2px;
  left: 9px;
  width: 6px;
  height: 14px;
  border: solid #ffffff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.profileView{
  background-color: #F6F6F6;
}




.reviewEdi{
  width: 100%;
  padding: 10px;
  margin-top: 6px;
  
 
}
.select-state{
  width: 100%;
    border: 0;
    margin: 0 0 15px;
    padding: 15px;
    outline: 0;
    font-size: 15px;
    box-sizing: border-box;
    background-color: #FCFCFC !important;
    box-shadow: 0px 0px 2px #0000000a;
    border: 1px solid #E1E1E1;
    border-radius: 4px;
}
.file-input__input {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
.white{
  color: #fff;
}

.payType-item input:checked ~ .liwLeft .checkmark {
  background-color: #f6491e !important;
}
.payType-item .liwLeft .checkmark {
  border-radius: 50%;
}
.payType-item .liwLeft .checkmark {
  height: 25px;
  width: 25px;
  background-color: #eee;
  display: block;
  border-radius: 50%;
  position: relative;
}
.payType-item input:checked ~ .liwLeft .checkmark::after {
  content: "";
  position: absolute;
}
.payType-item input:checked ~ .liwLeft .checkmark::after {
  left: 9px;
  top: 4px;
  width: 7px;
  height: 14px;
  border: solid #fff;
  border-width: 0 3px 3px 0px;
  transform: rotate(45deg);
}
.payType-item .liwLeft .checkmark::after {
  display: block;
}

input[type=number] {
  -moz-appearance: textfield !important;

}

.formstructure{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.formLikeInline .form-controller{
  display: flex;
  justify-content: center;
  gap: 10px;
}
.formLikeInline .form-controller input{
    width: 64px !important;
    height: 50px !important;
}
.updatebtn{
  margin-top: 20px;
}
.accRegistration{
  background: #F6F6F6;
}
button.backBtn{
  background: #F6491E 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
  font: normal normal 600 16px Segoe UI;
  letter-spacing: 0px;
  color: #FFFFFF;
  text-transform: uppercase;
  opacity: 1;
  width: 100%;
}
.bkBtn{
  text-align: center;
}
.sdLogin.success-dec h2{
  color: #F6491E;
}
.successWrp{
  display: flex;
  align-items: center;
  justify-content: center;
}
.unique-shedule.reshedule{
  flex-direction: row;
}
.spSliderWrap{
  display: flex;
  align-items: center;
  gap:20px;
  @include responsive(phone){
    justify-content: center;
  }
}
.spSliderWrap .navThumb{
  height: 100%;
}
.SpsliderLeft .slick-slide{
  float: none;
  width: 100% !important;
}
.SpsliderLeft{
  width: 100px;
  @include responsive(phone){
    width: 60px;
  }
}
.SpsliderLeft .slick-track{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.spSliderRt{
  width:calc(100% - 100px);
}
.spSliderRt .slick-arrow{
  display: none !important;
}
.shopBnr{
  &::before{
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: #140400 0% 0% no-repeat padding-box;
    opacity: 0.50;
    content: "";
  }
  .WrapperBnr{
    position: relative;
  }
.no-top{
  border-top: none;
}

}
.alertNote {
  margin-bottom: 20px;
  span{
    color: #F6491E;
    background: #f6491e0f 0% 0% no-repeat padding-box;
    border-radius: 4px;
    display: inline-block;
    padding: 8px 10px;
    font: normal normal normal 17px/16px Source Sans Pro;
    letter-spacing: 0px;
    color: #F6491E;
    opacity: 1;
  }
  img{
    width: 30px;
    height: 30px;
    margin-right: 5px;
  }
}
.green{
  color: green;
}
// .modal.show .modal-dialog {
//   width: 495px;
//   margin-top: 10%;
// }
// .modal.show .modal-dialog .supportBtn {
// width: 44%;
// }